import { ArrowUpTrayIcon } from "@heroicons/react/24/outline"
import classNames from "classnames"
import React, { ChangeEvent, FC, useState } from "react"
import { InputLabel } from "./inputLabel"

interface Props {
  id: string
  accept: string
  onUpload: (ev: ChangeEvent<HTMLInputElement>) => void
  text?: string
}

export const FileUpload: FC<Props> = ({ id, onUpload, accept, text = "or drag and drop" }) => {
  const [dragOver, setDragOver] = useState(false)

  return (
    <label
      htmlFor={id}
      className={classNames(
        "flex flex-col transition-all justify-center text-center w-full h-full px-6 pt-5 pb-6 border-2 border-dashed rounded-md border-gray-300 dark:border-gray-600 hover:border-indigo-600 dark:hover:border-indigo-300 focus-within:border-indigo-500 dark:focus-within:border-indigo-300",
        {
          "border-indigo-600": dragOver,
        }
      )}
    >
      <ArrowUpTrayIcon className="mx-auto h-6 w-6 mb-1 text-gray-400" />
      <span className="text-sm text-gray-600 dark:text-gray-400">
        Upload a file
        <br />
        {text}
      </span>

      <InputLabel
        onChange={onUpload}
        id={id}
        name={id}
        type="file"
        accept={accept}
        className="absolute inset-0 w-full opacity-0 cursor-pointer"
        inputClassName="cursor-pointer"
        onDragOver={() => setDragOver(true)}
        onDragLeave={() => setDragOver(false)}
        onDrop={() => setDragOver(false)}
      />
    </label>
  )
}
