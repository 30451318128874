import { Button, ButtonProps } from "components/core/button"
import { TextareaLabel } from "components/core/textareaLabel"
import { FeedbackTopic, FeedbackType, useAddFeedback } from "components/layout/useFeedback"
import { Form, Formik } from "formik"
import { inexhaustive } from "lib/utils"
import React, { FC, useState } from "react"
import { nanoid } from "nanoid"
import { Modal } from "components/core/modal/modal"
import { Title } from "components/core/modal/title"
import { InputLabel } from "components/core/inputLabel"
import { Spinner } from "components/core/spinner"
import { EllipsisHorizontalIcon, ExclamationTriangleIcon, LightBulbIcon } from "@heroicons/react/24/outline"
import { Link } from "components/core/link"
import { ChatBubbleOvalLeftEllipsisIcon } from "@heroicons/react/24/outline"

interface Props {
  buttonVariant?: ButtonProps["variant"]
}

export const Feedback: FC<Props> = ({ buttonVariant } = { buttonVariant: "secondary" }) => {
  const [showModal, setShowModal] = useState(false)
  const [feedbackType, setFeedbackType] = useState<FeedbackType>()

  const onClose = () => {
    setFeedbackType(undefined)
    setShowModal(false)
  }

  return (
    <>
      <Button variant={buttonVariant} onClick={() => setShowModal(true)}>
        <ChatBubbleOvalLeftEllipsisIcon className="h-5 w-5 mr-1.5" />
        Give feedback
      </Button>

      <Modal show={showModal} className="max-w-2xl" onClose={onClose}>
        {feedbackType ? (
          <FeedbackForm type={feedbackType} setFeedbackType={setFeedbackType} onSubmitted={onClose} />
        ) : (
          <TypeSelection setFeedbackType={setFeedbackType} />
        )}
      </Modal>
    </>
  )
}

const getFormTexts = (type: FeedbackType) => {
  if (type === FeedbackType.ISSUE) {
    return {
      title: "Report an issue",
      placeholder: "I noticed that...",
    }
  } else if (type === FeedbackType.IDEA) {
    return {
      title: "Share an idea",
      placeholder: "I would love...",
    }
  } else if (type === FeedbackType.OTHER) {
    return {
      title: "Tell us anything!",
      placeholder: "What do you want us to know?",
    }
  }

  return inexhaustive(type)
}

const FeedbackForm: FC<{
  type: FeedbackType
  setFeedbackType: (val: FeedbackType | undefined) => void
  onSubmitted: () => void
}> = ({ type, setFeedbackType, onSubmitted }) => {
  const { mutateAsync: addFeedback } = useAddFeedback()
  const { title, placeholder } = getFormTexts(type)

  return (
    <Formik
      initialValues={{ feedback: "", email: "" }}
      validate={async (values) => {
        const errors: {
          [key: string]: string
        } = {}

        if (!values.feedback.trim()) {
          errors.feedback = "Please enter something"
        }

        if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
          errors.email = "Please provide an email address (or leave it out)"
        }

        return errors
      }}
      onSubmit={async (values, { setSubmitting }) => {
        await addFeedback({
          key: nanoid(),
          topic: FeedbackTopic.GENERAL,
          value: values.feedback,
          email: values.email,
          type,
        })

        setSubmitting(false)
        onSubmitted()
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
        return (
          <Form className="relative" onSubmit={handleSubmit} autoComplete="off">
            {isSubmitting && (
              <div className="absolute inset-0 bg-white dark:bg-gray-800 bg-opacity-70 dark:bg-opacity-70 z-20 flex items-center justify-center">
                <Spinner className="w-10 h-10 text-indigo-600 dark:text-indigo-400" />
              </div>
            )}
            <Button
              size="small"
              variant="naked"
              className="absolute -left-2 -top-1 text-gray-400 hover:text-gray-500 dark:hover:text-gray-100"
              onClick={() => setFeedbackType(undefined)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
              </svg>
            </Button>
            <label
              htmlFor={title}
              className="block mb-5 text-center text-lg leading-6 font-medium text-gray-900 dark:text-gray-200"
            >
              {title}
            </label>

            <TextareaLabel
              id="feedback"
              rows={4}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.feedback}
              errors={errors.feedback && touched.feedback ? errors.feedback : null}
              placeholder={placeholder}
            />

            <div className="mt-3">
              <InputLabel
                type="text"
                id="email"
                label="Email (optional)"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                errors={errors.email && touched.email ? errors.email : null}
                placeholder={"In case you would like to get a reply"}
              />
            </div>

            <div className="text-right mt-6">
              <Button type="submit" variant="primary" disabled={isSubmitting}>
                Send feedback
              </Button>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

const TypeSelection: FC<{ setFeedbackType: (val: FeedbackType | undefined) => void }> = ({ setFeedbackType }) => (
  <>
    <Title title="Any feedback to share?" />
    <div className="grid grid-cols-3 gap-6 mt-6">
      <Button className="flex-col sm:!p-8" onClick={() => setFeedbackType(FeedbackType.ISSUE)}>
        <ExclamationTriangleIcon className="h-8 w-8 text-red-600 dark:text-[#e5484d]" />
        <span className="text-md">Issue</span>
      </Button>
      <Button className="flex-col sm:!p-8" onClick={() => setFeedbackType(FeedbackType.IDEA)}>
        <LightBulbIcon className="h-8 w-8 text-yellow-600" />
        <span className="text-md">Idea</span>
      </Button>
      <Button className="flex-col sm:!p-8" onClick={() => setFeedbackType(FeedbackType.OTHER)}>
        <EllipsisHorizontalIcon className="h-8 w-8" />
        <span className="text-md">Other</span>
      </Button>
    </div>

    <div className="my-6 flex items-center">
      <div className="h-px flex-1 bg-gray-200 dark:bg-gray-700/30" />
      <div className="mx-3 text-sm opacity-60">OR</div>
      <div className="h-px flex-1 bg-gray-200 dark:bg-gray-700/30" />
    </div>

    <div className="flex justify-center mb-3">
      <Link
        to="https://github.com/damianfrizzi/fetoolkit"
        target="_blank"
        rel="noreferrer noopener"
        variant="secondary"
      >
        <svg width="24" height="24" fill="currentColor" className="mr-3 text-opacity-50 transform">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 2C6.477 2 2 6.463 2 11.97c0 4.404 2.865 8.14 6.839 9.458.5.092.682-.216.682-.48 0-.236-.008-.864-.013-1.695-2.782.602-3.369-1.337-3.369-1.337-.454-1.151-1.11-1.458-1.11-1.458-.908-.618.069-.606.069-.606 1.003.07 1.531 1.027 1.531 1.027.892 1.524 2.341 1.084 2.91.828.092-.643.35-1.083.636-1.332-2.22-.251-4.555-1.107-4.555-4.927 0-1.088.39-1.979 1.029-2.675-.103-.252-.446-1.266.098-2.638 0 0 .84-.268 2.75 1.022A9.606 9.606 0 0112 6.82c.85.004 1.705.114 2.504.336 1.909-1.29 2.747-1.022 2.747-1.022.546 1.372.202 2.386.1 2.638.64.696 1.028 1.587 1.028 2.675 0 3.83-2.339 4.673-4.566 4.92.359.307.678.915.678 1.846 0 1.332-.012 2.407-.012 2.734 0 .267.18.577.688.48C19.137 20.107 22 16.373 22 11.969 22 6.463 17.522 2 12 2z"
          ></path>
        </svg>
        Create issue on GitHub
      </Link>
    </div>
  </>
)
