import classNames from "classnames"
import React, { FC, useState, useEffect } from "react"
import { Switch } from "@headlessui/react"

export const THEME_STORAGE_EVENT = "THEME_CHANGED"

export const ThemeToggle: FC = () => {
  const [darkModeEnabled, setDarkModeEnabled] = useState(true)

  const enableDarkMode = () => {
    document.querySelector("html")?.classList.add("noTransition")
    document.documentElement.classList.add("dark")
    localStorage.theme = "dark"
    setDarkModeEnabled(true)
    window.dispatchEvent(new Event(THEME_STORAGE_EVENT))
    setTimeout(() => {
      document.querySelector("html")?.classList.remove("noTransition")
    }, 0)
  }

  const disableDarkMode = () => {
    document.querySelector("html")?.classList.add("noTransition")
    document.documentElement.classList.remove("dark")
    localStorage.theme = "light"
    setDarkModeEnabled(false)
    window.dispatchEvent(new Event(THEME_STORAGE_EVENT))
    setTimeout(() => {
      document.querySelector("html")?.classList.remove("noTransition")
    }, 0)
  }

  useEffect(() => {
    if (localStorage.theme === "light") {
      setDarkModeEnabled(false)
    }
  }, [])

  return (
    <Switch
      checked={darkModeEnabled}
      onChange={(enabled) => {
        if (enabled) {
          enableDarkMode()
        } else {
          disableDarkMode()
        }
      }}
      className={classNames(
        "relative inline-flex items-center h-6 rounded-full w-11 bg-gray-200 bg-opacity-80 dark:bg-gray-800 focus-visible:ring-2 focus-visible:ring-indigo-500"
      )}
    >
      <span className="sr-only">Enable dark mode</span>
      <span
        className={classNames(
          "inline-block w-4 h-4 transform transition ease-in-out duration-200 bg-white rounded-full",
          {
            "translate-x-6": darkModeEnabled,
            "translate-x-1": !darkModeEnabled,
          }
        )}
      />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={classNames("h-4 w-4 transition ease-in-out duration-200 absolute top-1 left-[5px] text-indigo-200", {
          "opacity-100": darkModeEnabled,
          "opacity-0": !darkModeEnabled,
        })}
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={classNames("h-4 w-4 transition ease-in-out duration-200 absolute top-1 right-[5px] text-gray-700", {
          "opacity-0": darkModeEnabled,
          "opacity-100": !darkModeEnabled,
        })}
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
        />
      </svg>
    </Switch>
  )
}
