interface LayoutConstructor {
  id?: number | string | null
  user_id?: string | null
  is_private: boolean
  color?: string | null
  xs: any
  md: any
  lg: any
  xl: any
  xxl: any
}

export interface Breakpoint {
  w: number
  h: number
  x: number
  y: number
  minW: number
  minH: number
  i?: string
  moved?: boolean
  static?: boolean
}

export class Layout {
  id: Readonly<number | string | null>
  user_id: Readonly<string | null>
  color: Readonly<string | null>
  is_private: boolean
  xs: Breakpoint
  md: Breakpoint
  lg: Breakpoint
  xl: Breakpoint
  xxl: Breakpoint

  private static REQUIRED_BREAKPOINT_PROPERTIES = ["w", "h", "x", "y", "minW", "minH"] as const
  private static BREAKPOINTS = ["xs", "md", "lg", "xl", "xxl"] as const

  constructor(layout: LayoutConstructor) {
    const { id, user_id, is_private, color, xs, md, lg, xl, xxl } = layout
    Layout.validateBreakpoints(layout)
    Layout.validateUserIdAndIsPrivate(user_id, is_private)

    this.id = id ?? null
    this.user_id = user_id ?? null
    this.is_private = is_private
    this.color = color ?? null
    this.xs = xs
    this.md = md
    this.lg = lg
    this.xl = xl
    this.xxl = xxl
  }

  private static validateBreakpoints = (layout: LayoutConstructor) => {
    Layout.BREAKPOINTS.forEach((breakpoint) => {
      const layoutBreakpoint = layout[breakpoint]
      Layout.REQUIRED_BREAKPOINT_PROPERTIES.forEach((property) => {
        const breakpointProp = layoutBreakpoint[property]
        if (breakpointProp === undefined || breakpointProp === null || typeof breakpointProp !== "number") {
          throw new Error(`Object has no property ${property} and cannot be used as a breakpoint object.`)
        }
      })
    })
  }

  private static validateUserIdAndIsPrivate = (
    userId: LayoutConstructor["user_id"],
    isPrivate: LayoutConstructor["is_private"]
  ) => {
    if (!userId && isPrivate === true) {
      throw new Error("Layout without a user_id cannot be private.")
    }
  }
}
