import classNames from "classnames"
import { FORM_BASE_CLASS_NAMES } from "components/core/inputLabel"
import { FC, ReactNode, TextareaHTMLAttributes } from "react"

interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  id: string
  label?: ReactNode
  inputIconBefore?: ReactNode
  inputIconAfter?: ReactNode
  inputAfter?: ReactNode
  errors?: ReactNode
  resize?: boolean
  rounded?: boolean
}

export const TextareaLabel: FC<Props> = ({
  className,
  id,
  label,
  inputAfter,
  inputIconBefore,
  inputIconAfter,
  errors,
  resize,
  ...rest
}) => (
  <div className={classNames("h-full")}>
    {label && (
      <label htmlFor={id} className="block mb-1 text-sm font-medium text-gray-700 dark:text-gray-300">
        {label}
      </label>
    )}
    <div className="flex h-full rounded-md shadow-sm">
      <div className="relative flex items-stretch flex-grow">
        {inputIconBefore && (
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">{inputIconBefore}</div>
        )}

        <textarea
          id={id}
          name={id}
          {...rest}
          className={classNames(FORM_BASE_CLASS_NAMES, className, {
            "pl-10": inputIconBefore,
            "rounded-r-none": inputAfter,
            "resize-none": !resize,
          })}
        />

        {inputIconAfter && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">{inputIconAfter}</div>
        )}
      </div>
      {inputAfter}
    </div>
    {errors && <div className="mt-2 text-sm text-red-600 dark:text-[#e5484d]">{errors}</div>}
  </div>
)
