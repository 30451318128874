import { Layout } from "domainModels/layout"
import { Widget, WidgetInformation } from "domainModels/widget"
import { useUser } from "lib/supabase/auth"
import { nanoid } from "nanoid"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { Layout as ReactGridLayout, Layouts } from "react-grid-layout"
import {
  deleteWidget,
  deleteWidgetLayout,
  insertWidget,
  selectPublicWidgets,
  selectPublicWidgetsCount,
  selectWidgets,
  updateWidget,
  updateWidgetRow,
} from "repository/widget"
import { Group } from "domainModels/group"
import { getGroupsKey, GROUPS_LOCAL_STORAGE_KEY, useInsertGroup } from "reactQuery/group"
import { trackLegacyDashboard } from "lib/gtag"
import { ANONYMOUS_DASHBOARD_ID, ANONYMOUS_USER_ID } from "components/dashboard/anonymousConfig"
import { Optional } from "lib/utils"
import { queryClient } from "components/providers/reactQueryProvider"
import { WIDGETS_ON_DEFAULT_DASHBOARD } from "lib/features/features"
import { DEFAULT_CUSTON_WIDGET_BREAKPOINT } from "components/dashboard/grid/config"

export const getWidgetsKey = (dashboardId?: number | null) => (dashboardId ? ["widgets", dashboardId] : ["widgets"])

export const useSelectWidgets = (dashboardId?: number | null) => {
  const { data: publicWidgets } = useSelectPublicWidgets()
  const { user } = useUser()

  return useQuery<Widget[] | null, Error>(
    getWidgetsKey(dashboardId),
    async () => {
      if (!dashboardId) {
        return null
      }

      if (!user && dashboardId === ANONYMOUS_DASHBOARD_ID) {
        return initializeLocalStorageIfNecessary({
          dashboardId,
          userId: ANONYMOUS_USER_ID,
          publicWidgets: publicWidgets?.filter(
            (w) => w.information.slug && WIDGETS_ON_DEFAULT_DASHBOARD.includes(w.information.slug)
          ),
        })
      }

      return selectWidgets(dashboardId)
    },
    { enabled: !!dashboardId && !!publicWidgets }
  )
}

const getSelectPublicWidgetsCountKey = (dashboardId?: number | null) => ["publicWidgetsCount", dashboardId]
export const useSelectPublicWidgetsCount = (dashboardId?: number | null) =>
  useQuery<number | null, Error>(
    getSelectPublicWidgetsCountKey(dashboardId),
    async () => selectPublicWidgetsCount(dashboardId!),
    {
      enabled: !!dashboardId,
    }
  )

export const useCombineWidgetsToGroup = () => {
  const queryClient = useQueryClient()
  const { mutateAsync: insertGroup } = useInsertGroup()
  const { user } = useUser()

  return useMutation<
    void,
    Error,
    {
      sourceWidget: Widget
      targetWidget: Widget
      targetLayout: ReactGridLayout
      dashboardId: number
      isPrivate: boolean
    }
  >(async ({ sourceWidget, targetWidget, targetLayout, dashboardId, isPrivate }) => {
    const newLayout = {
      w: targetLayout.w,
      h: targetLayout.h,
      x: targetLayout.x,
      y: targetLayout.y,
      minW: DEFAULT_CUSTON_WIDGET_BREAKPOINT.minW,
      minH: DEFAULT_CUSTON_WIDGET_BREAKPOINT.minH,
    }

    const group = await insertGroup({
      dashboardId,
      group: new Group({
        name: "New Group",
        user_id: user?.id,
        is_private: isPrivate,
        layout: new Layout({
          is_private: isPrivate,
          user_id: user?.id,
          xs: newLayout,
          md: newLayout,
          lg: newLayout,
          xl: newLayout,
          xxl: newLayout,
        }),
      }),
    })
    const newSourceWidget = { ...sourceWidget, group, layout: null }
    const newTargetWidget = { ...targetWidget, group, layout: null }
    const addNewWidgets = (widgets: Widget[] = []) =>
      widgets.map((w) => {
        if (w.id === sourceWidget.id) {
          return newSourceWidget
        } else if (w.id === targetWidget.id) {
          return newTargetWidget
        }
        return w
      })

    queryClient.setQueryData<Widget[]>(getWidgetsKey(dashboardId), addNewWidgets)

    if (user && dashboardId !== ANONYMOUS_DASHBOARD_ID) {
      await Promise.all([
        updateWidgetRow(newSourceWidget),
        updateWidgetRow(newTargetWidget),
        new Promise<void>(async (resolve) => {
          if (sourceWidget.layout?.id) {
            await deleteWidgetLayout(+sourceWidget.layout.id)
          }
          resolve()
        }),
        new Promise<void>(async (resolve) => {
          if (targetWidget.layout?.id) {
            await deleteWidgetLayout(+targetWidget.layout.id)
          }
          resolve()
        }),
      ])
    } else {
      localStorage.setItem(WIDGETS_LOCAL_STORAGE_KEY, JSON.stringify(addNewWidgets(getWidgetsFromLocalStorage() ?? [])))
    }
  })
}

export const useUpdateWidget = () => {
  const queryClient = useQueryClient()
  const { user } = useUser()

  return useMutation<void, Error, { widget: Optional<Widget, "information">; dashboardId: number }>(
    async ({ widget, dashboardId }) => {
      queryClient.setQueryData<Widget[]>(getWidgetsKey(dashboardId), (oldData = []) =>
        oldData.map((o) =>
          o.id === widget.id
            ? {
                ...widget,
                information: widget.information ?? o.information,
              }
            : o
        )
      )

      if (user && dashboardId !== ANONYMOUS_DASHBOARD_ID) {
        await updateWidget(widget)
      } else {
        localStorage.setItem(
          WIDGETS_LOCAL_STORAGE_KEY,
          JSON.stringify(
            getWidgetsFromLocalStorage()?.map((w) =>
              w.id === widget.id ? { ...widget, information: widget.information ?? w.information } : w
            )
          )
        )
      }
    }
  )
}

export const useInsertWidget = () => {
  const queryClient = useQueryClient()
  const { user } = useUser()

  return useMutation<Widget, Error, { dashboardId: number; widget: Widget }>(async ({ dashboardId, widget }) => {
    if (user && dashboardId !== ANONYMOUS_DASHBOARD_ID) {
      const insertedWidget = await insertWidget(widget)
      queryClient.setQueryData<Widget[]>(getWidgetsKey(dashboardId), (oldData = []) => [...oldData, insertedWidget])
      return insertedWidget
    } else {
      const insertedWidget: Widget = {
        ...widget,
        id: nanoid(),
        layout: widget.layout
          ? { ...widget.layout, user_id: ANONYMOUS_USER_ID, is_private: false, id: nanoid() }
          : null,
      }
      const widgets = getWidgetsFromLocalStorage()
      queryClient.setQueryData<Widget[]>(getWidgetsKey(dashboardId), (oldData = []) => [...oldData, insertedWidget])
      if (widgets) {
        localStorage.setItem(WIDGETS_LOCAL_STORAGE_KEY, JSON.stringify([...widgets, insertedWidget]))
      }
      return widget
    }
  })
}

export const useDeleteWidget = () => {
  const queryClient = useQueryClient()
  const { data: publicWidgets } = useSelectPublicWidgets()
  const { user } = useUser()

  return useMutation<void, Error, { widget: Widget; dashboardId: number }>(async ({ widget, dashboardId }) => {
    queryClient.setQueryData<Widget[]>(getWidgetsKey(dashboardId), (oldData = []) =>
      oldData.filter((o) => o.id !== widget.id)
    )

    if (user && dashboardId !== ANONYMOUS_DASHBOARD_ID) {
      await deleteWidget(widget, !!publicWidgets?.some((w) => w.information.id === widget.information.id))
    } else {
      const widgets = getWidgetsFromLocalStorage()
      if (widgets) {
        localStorage.setItem(WIDGETS_LOCAL_STORAGE_KEY, JSON.stringify(widgets.filter((w) => w.id !== widget.id)))
      }
    }
  })
}

export const getPublicWidgetsKey = () => ["widgets", "public"]

export const useSelectPublicWidgets = () =>
  useQuery<Widget[] | null, Error>(getPublicWidgetsKey(), async () => selectPublicWidgets())

export const WIDGETS_LOCAL_STORAGE_KEY = "widgets"
export const getWidgetsFromLocalStorage = () => {
  let widgets: Widget[] | null = null

  try {
    widgets = JSON.parse(localStorage.getItem(WIDGETS_LOCAL_STORAGE_KEY)!)
  } finally {
    return widgets
  }
}

export const initializeLocalStorageIfNecessary = ({
  dashboardId,
  userId,
  publicWidgets,
}: {
  dashboardId: number
  userId: string
  publicWidgets?: Widget[] | null
}): Widget[] => {
  if (!getWidgetsFromLocalStorage()) {
    const nonExternalWidgets = publicWidgets?.filter((w) => w.information.slug) ?? []

    const legacyGridLayouts: Layouts = JSON.parse(localStorage.getItem("gridLayouts") as string)
    const legacyGridRemovedWidgets: string[] = JSON.parse(localStorage.getItem("gridRemovedWidgets") as string) ?? []
    const legacyGridCustomWidgets: {
      name: string
      description: string
      grid: any
      keywords: string[]
      modal: { iframeUrl: string }
    }[] = JSON.parse(localStorage.getItem("gridCustomWidgets") as string) ?? []
    const legacyGridGroupedWidgets: { id: string; name: string; items: string[] }[] =
      JSON.parse(localStorage.getItem("gridGroupedWidgets") as string) ?? []

    if (
      legacyGridLayouts ||
      legacyGridRemovedWidgets.length ||
      legacyGridCustomWidgets.length ||
      legacyGridGroupedWidgets.length
    ) {
      trackLegacyDashboard(
        legacyGridLayouts,
        legacyGridRemovedWidgets,
        legacyGridCustomWidgets,
        legacyGridGroupedWidgets
      )
    } else {
      /**
       * https://github.com/damianfrizzi/frontendtoolkit/issues/175
       * Once we no longer receive events in GA about people with old localStorage structure we can remove everything except this else branch
       */
      const defaultWidgets = nonExternalWidgets?.map((w) => ({
        ...w,
        id: nanoid(),
        dashboard_id: dashboardId,
        layout: w.layout
          ? {
              ...w.layout,
              id: nanoid(),
            }
          : null,
      }))
      localStorage.setItem(WIDGETS_LOCAL_STORAGE_KEY, JSON.stringify(defaultWidgets))

      return defaultWidgets ?? []
    }

    const getLegacyLayout = (identifier: string) => {
      const xs = legacyGridLayouts.xs?.find((l: any) => l.i === identifier)
      const md = legacyGridLayouts.md?.find((l: any) => l.i === identifier)
      const lg = legacyGridLayouts.lg?.find((l: any) => l.i === identifier)
      const xl = legacyGridLayouts.xl?.find((l: any) => l.i === identifier)
      const xxl = legacyGridLayouts.xxl?.find((l: any) => l.i === identifier)

      if (xs && md && lg && xl && xxl) {
        const { i: xsI, ...xsLayout } = xs
        const { i: mdI, ...mdLayout } = md
        const { i: lgI, ...lgLayout } = lg
        const { i: xlI, ...xlLayout } = xl
        const { i: xxlI, ...xxlLayout } = xxl

        return new Layout({
          is_private: false,
          user_id: ANONYMOUS_USER_ID,
          id: nanoid(),
          xs: xsLayout,
          md: mdLayout,
          lg: lgLayout,
          xl: xlLayout,
          xxl: xxlLayout,
        })
      }
      return undefined
    }

    const groups = new Map<string, Group>()

    legacyGridGroupedWidgets.forEach((legacyGroup) => {
      groups.set(
        legacyGroup.id,
        new Group({
          id: nanoid(),
          user_id: ANONYMOUS_USER_ID,
          is_private: false,
          name: legacyGroup.name,
          layout: getLegacyLayout(legacyGroup.id)!,
        })
      )
    })

    queryClient.setQueryData<Group[]>(getGroupsKey(dashboardId), Array.from(groups.values()))
    localStorage.setItem(GROUPS_LOCAL_STORAGE_KEY, JSON.stringify(Array.from(groups.values())))

    const widgets = publicWidgets
      ?.map((w) => {
        const legacyGridCustomWidget = legacyGridCustomWidgets.find((lw) => lw.name === w.information.name)
        const legacyGroup = legacyGridGroupedWidgets.find(
          (lw) =>
            (w.information.slug && lw.items.includes(w.information.slug)) ||
            (legacyGridCustomWidget?.name && lw.items.includes(legacyGridCustomWidget?.name))
        )
        const legacyLayout = w.information.slug
          ? getLegacyLayout(w.information.slug)
          : w.information.name
          ? getLegacyLayout(w.information.name)
          : undefined
        const legacyIsRemoved = !!w.information.slug && legacyGridRemovedWidgets.includes(w.information.slug)

        if (legacyIsRemoved || (!legacyLayout && !legacyGroup)) {
          return null
        }

        return new Widget({
          ...w,
          id: nanoid(),
          dashboard_id: dashboardId,
          user_id: userId,
          information:
            w.dashboard_id === null
              ? w.information
              : new WidgetInformation({
                  id: nanoid(),
                  user_id: ANONYMOUS_USER_ID,
                  is_private: false,
                  name: legacyGridCustomWidget?.name ?? "",
                  description: legacyGridCustomWidget?.description,
                  external_url: legacyGridCustomWidget?.modal.iframeUrl,
                }),
          group: legacyGroup ? groups.get(legacyGroup.id) : undefined,
          layout: !legacyGroup ? legacyLayout : undefined,
        })
      })
      .filter(Boolean)

    localStorage.setItem(WIDGETS_LOCAL_STORAGE_KEY, JSON.stringify(widgets))

    localStorage.removeItem("gridRemovedWidgets")
    localStorage.removeItem("gridLayouts")
    localStorage.removeItem("gridCustomWidgets")
    localStorage.removeItem("gridGroupedWidgets")
  }
  return getWidgetsFromLocalStorage() ?? []
}
