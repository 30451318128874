import { useMutation, useQuery, useQueryClient } from "react-query"
import { Group } from "domainModels/group"
import { deleteGroup, insertGroup, selectGroups, updateGroup } from "repository/group"
import { useUser } from "lib/supabase/auth"
import { nanoid } from "nanoid"
import { ANONYMOUS_DASHBOARD_ID } from "components/dashboard/anonymousConfig"

export const getGroupsKey = (dashboardId?: number | null) => (dashboardId ? ["groups", dashboardId] : [])

export const useSelectGroups = (dashboardId?: number | null) =>
  useQuery<Group[] | null, Error>(
    getGroupsKey(dashboardId),
    async () => {
      if (dashboardId === ANONYMOUS_DASHBOARD_ID) {
        return getGroupsFromLocalStorage()
      }
      return selectGroups(dashboardId!)
    },
    { enabled: !!dashboardId }
  )

export const useUpdateGroup = () => {
  const queryClient = useQueryClient()
  const { user } = useUser()

  return useMutation<void, Error, { group: Group; dashboardId: number }>(async ({ group, dashboardId }) => {
    queryClient.setQueryData<Group[]>(getGroupsKey(dashboardId), (oldData = []) =>
      oldData.map((o) => (o.id === group.id ? group : o))
    )

    if (user) {
      await updateGroup(group)
    } else {
      localStorage.setItem(
        GROUPS_LOCAL_STORAGE_KEY,
        JSON.stringify(getGroupsFromLocalStorage().map((g) => (g.id === group.id ? group : g)))
      )
    }
  })
}

export const useInsertGroup = () => {
  const queryClient = useQueryClient()
  const { user } = useUser()

  return useMutation<Group, Error, { group: Group; dashboardId: number }>(async ({ group, dashboardId }) => {
    if (user) {
      const insertedGroup = await insertGroup(group)
      queryClient.setQueryData<Group[]>(getGroupsKey(dashboardId), (oldData = []) => [...oldData, insertedGroup])
      return insertedGroup
    } else {
      const insertedGroup: Group = { ...group, id: nanoid(), layout: { ...group.layout, id: nanoid() } }
      queryClient.setQueryData<Group[]>(getGroupsKey(dashboardId), (oldData = []) => [...oldData, insertedGroup])
      localStorage.setItem(GROUPS_LOCAL_STORAGE_KEY, JSON.stringify([...getGroupsFromLocalStorage(), insertedGroup]))
      return insertedGroup
    }
  })
}

export const useDeleteGroup = () => {
  const queryClient = useQueryClient()
  const { user } = useUser()

  return useMutation<void, Error, { group: Group; dashboardId: number }>(async ({ group, dashboardId }) => {
    queryClient.setQueryData<Group[]>(getGroupsKey(dashboardId), (oldData = []) =>
      oldData.filter((o) => o.id !== group.id)
    )

    if (user) {
      await deleteGroup(group)
    } else {
      localStorage.setItem(
        GROUPS_LOCAL_STORAGE_KEY,
        JSON.stringify(getGroupsFromLocalStorage().filter((g) => g.id !== group.id))
      )
    }
  })
}

export const GROUPS_LOCAL_STORAGE_KEY = "groups"
export const getGroupsFromLocalStorage = () => {
  let groups: Group[] | null = null

  try {
    groups = JSON.parse(localStorage.getItem(GROUPS_LOCAL_STORAGE_KEY)!)
  } finally {
    return groups ? groups : []
  }
}
