import classNames from "classnames"
import { FC } from "react"
import toast from "react-hot-toast"
import { ReactNode } from "reactcss/node_modules/@types/react"
import { Button, ButtonProps } from "./button"

export const copyToClipboard = async (text: string) => {
  await toast.promise(navigator.clipboard.writeText(text), {
    loading: "Copying...",
    success: "Copied to clipboard!",
    error: "Could not copy to clipboard.",
  })
}

interface Props {
  text?: string
  className?: string
  label?: ReactNode
  labelClassName?: string
  size?: ButtonProps["size"]
  variant?: ButtonProps["variant"]
  disabled?: boolean
}

export const CopyButton: FC<Props> = ({
  text,
  disabled,
  label,
  labelClassName,
  variant,
  size = "regular",
  className,
}) => (
  <Button
    size={size}
    disabled={disabled}
    variant={variant}
    title="Copy to clipboard"
    className={classNames("group", className)}
    onClick={() => {
      if (text) {
        copyToClipboard(text)
      }
    }}
  >
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames("stroke-current w-5 h-5", { "transform group-hover:rotate-[-4deg] transition": !disabled })}
    >
      <path
        d="M8.29 4.64H6.64A2.64 2.64 0 004 7.28V18.5a2.64 2.64 0 002.64 2.64h11.22a2.64 2.64 0 002.64-2.64V7.28a2.64 2.64 0 00-2.64-2.64h-1.65"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.89 6.62H9.61A1.32 1.32 0 018.29 5.3V3.32c0-.729.591-1.32 1.32-1.32h5.28c.73 0 1.32.591 1.32 1.32V5.3c0 .729-.59 1.32-1.32 1.32zM9.28 11.9h5.94M9.28 15.86h5.94"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
    {label && <span className={classNames("ml-2", labelClassName)}>{label}</span>}
  </Button>
)
