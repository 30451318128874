import { Layout } from "domainModels/layout"
import { createContext, FC, useContext, useState } from "react"

export interface UiContextEntry {
  showCustomWidgetModal: boolean
  showSuggestions: boolean
  suggestionInfo?: {
    layout: Pick<Layout, "xs" | "md" | "lg" | "xl" | "xxl">
    position?: "top" | "bottom"
  }
  searchQuery: string
}

export interface UiContextStore {
  update: (val: Partial<UiContextEntry>) => void
  value: UiContextEntry
}

const defaultValue: UiContextEntry = {
  showCustomWidgetModal: false,
  showSuggestions: false,
  suggestionInfo: undefined,
  searchQuery: "",
}

export const UiContext = createContext<UiContextStore>({
  value: defaultValue,
  update: () => null,
})

export const useUiContext = () => useContext<UiContextStore>(UiContext)

export const UiProvider: FC = ({ children }) => {
  const [ui, setUi] = useState(defaultValue)
  const uiContextStore: UiContextStore = {
    update: (val) => setUi({ ...ui, ...val }),
    value: ui,
  }

  return <UiContext.Provider value={uiContextStore}>{children}</UiContext.Provider>
}
