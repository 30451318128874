import { ColorEvent } from "components/dashboard/widgets/colorConverter/types"
import { HSLColor, RGBColor } from "react-color"
import tinycolor from "tinycolor2"

export const DEFAULT_COLOR = tinycolor("#4F46E5")

export const getColor = (colorEvent: ColorEvent) => {
  let color: tinycolor.Instance

  if (typeof colorEvent === "string") {
    color = tinycolor(colorEvent)
  } else if (colorEvent.source === "hsv") {
    color = tinycolor({
      h: colorEvent.h,
      s: colorEvent.s,
      v: colorEvent.v,
      a: colorEvent.a,
    })
  } else {
    color = tinycolor({
      h: colorEvent.h,
      s: colorEvent.s,
      l: colorEvent.l,
      a: colorEvent.a,
    })
  }

  return color
}

export const getRgbString = (rgb: RGBColor) => `rgb(${rgb.r},${rgb.g},${rgb.b})`
export const getRgbaString = (rgb: RGBColor) => `rgba(${rgb.r},${rgb.g},${rgb.b},${rgb.a})`
export const getHslString = (hsl: HSLColor) =>
  `hsl(${Math.round(hsl.h)},${Math.round(hsl.s * 100)}%,${Math.round(hsl.l * 100)})`
export const getHslaString = (hsl: HSLColor) =>
  `hsla(${Math.round(hsl.h)},${Math.round(hsl.s * 100)}%,${Math.round(hsl.l * 100)},${hsl.a})`
