import { Popover } from "@headlessui/react"
import { ShareIcon } from "@heroicons/react/24/outline"
import { CopyButton } from "components/core/copyButton"
import { InputLabel } from "components/core/inputLabel"
import { Link } from "components/core/link"
import { Feedback } from "components/layout/feedback"
import { Widget } from "domainModels/widget"
import { BASE_URL } from "lib/utils"
import { FC } from "react"
import { Card } from "./card"

interface ShareWidgetProps {
  widget: Widget
}

export const ShareWidget: FC<ShareWidgetProps> = ({ widget }) => (
  <Popover className="relative inline-flex ml-3" as="span">
    <Popover.Button className="rounded-md inline-flex items-center font-medium text-indigo-600 hover:text-indigo-900 dark:text-indigo-400 dark:hover:text-indigo-500 focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:border-indigo-500 text-sm">
      <ShareIcon className="w-5 h-5 mr-1.5" />
      Share
    </Popover.Button>

    <Popover.Panel className="absolute z-20 w-80 md:w-96 -translate-x-32 top-full mt-2 rounded-md shadow-xl">
      <Card className="p-4 rounded-lg">
        <ShareContent
          title={widget.information.name}
          shareInfo={{
            title: widget.information.name,
            description: widget.information.description ?? "",
            url: `${BASE_URL}/widget/${widget.information.slug}`,
          }}
        />
      </Card>
    </Popover.Panel>
    <span className="ml-3 hidden md:inline-flex">
      <Feedback buttonVariant="link" />
    </span>
  </Popover>
)

interface ShareContentProps {
  title: string
  shareInfo: {
    title: string
    description: string
    url: string
  }
}

export const ShareContent: FC<ShareContentProps> = ({ title, shareInfo }) => (
  <>
    <div className="text-center max-w-md mx-auto">
      <h3 className="mb-3 font-medium text-sm">{title}</h3>
      <InputLabel
        id="shareDashboard"
        type="text"
        defaultValue={shareInfo.url}
        inputAfter={<CopyButton className="-ml-px focus-visible:z-10 rounded-l-none" text={shareInfo.url} />}
      />
    </div>

    <div className="mt-4 mb-2 grid col-auto grid-flow-col gap-3 justify-center">
      <Link
        variant="secondary"
        size="small"
        to={`mailto:?subject=${encodeURIComponent(shareInfo.title)}&body=${encodeURIComponent(
          shareInfo.description + `\n\nCheckout ${shareInfo.url}`
        )}`}
        target="_blank"
        rel="noreferrer noopener"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
          />
        </svg>
      </Link>
      <Link
        variant="secondary"
        size="small"
        to={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
          shareInfo.description
        )}&url=${encodeURIComponent(shareInfo.url)}`}
        target="_blank"
        rel="noreferrer noopener"
      >
        <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"></path>
        </svg>
      </Link>
      <Link
        variant="secondary"
        size="small"
        to={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareInfo.url)}`}
        target="_blank"
        rel="noreferrer noopener"
      >
        <svg className="w-6 h-6" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9.198 21.5h4v-8.01h3.604l.396-3.98h-4V7.5a1 1 0 011-1h3v-4h-3a5 5 0 00-5 5v2.01h-2l-.396 3.98h2.396v8.01z"
            fill="currentColor"
          />
        </svg>
      </Link>
      <Link
        variant="secondary"
        size="small"
        to={`http://www.linkedin.com/shareArticle?mini=true&url=${shareInfo.url}&title=${encodeURIComponent(
          shareInfo.title
        )}&summary=${encodeURIComponent(shareInfo.description)}&source=fetoolkit.io`}
        target="_blank"
        rel="noreferrer noopener"
      >
        <svg className="w-5 h-5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433a2.062 2.062 0 01-2.063-2.065 2.064 2.064 0 112.063 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"
            fill="currentColor"
          />
        </svg>
      </Link>
      <Link
        variant="secondary"
        size="small"
        to={`https://web.whatsapp.com/send?text=${shareInfo.url}`}
        target="_blank"
        rel="noreferrer noopener"
      >
        <svg className="w-5 h-5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51a12.8 12.8 0 00-.57-.01c-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413z"
            fill="currentColor"
          />
        </svg>
      </Link>
    </div>
  </>
)
