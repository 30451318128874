import { DEFAULT_COLOR } from "components/dashboard/widgets/colorConverter/utils"
import React, { FC, useEffect, useState } from "react"
import toast from "react-hot-toast"
import tinycolor from "tinycolor2"
import { Modal } from "components/core/modal/modal"
import { Button } from "components/core/button"
import { InputLabel } from "components/core/inputLabel"
import { Widget } from "domainModels/widget"
import { colorConverterConfig } from "components/dashboard/widgets/colorConverter/colorConverterConfig"
import classNames from "classnames"

interface Props {
  widget: Widget
}

export const ColorConverterPreview: FC<Props> = ({ widget }) => {
  const [showModal, setShowModal] = useState(false)
  const layoutColor = widget.layout?.color
  const [input, setInput] = useState<tinycolor.Instance>(
    layoutColor ? tinycolor(layoutColor).darken(20) : DEFAULT_COLOR
  )

  useEffect(() => {
    setInput(layoutColor ? tinycolor(layoutColor).darken(20) : DEFAULT_COLOR)
  }, [layoutColor])

  return (
    <div className="h-full w-full flex items-center justify-center">
      <InputLabel
        id="colorConverter"
        type="text"
        value={input.toHexString()}
        aria-label="Enter color code"
        inputClassName={classNames("rounded-l-none focus-visible:z-20", {
          "focus-visible:z-10 !bg-white !bg-opacity-20 !text-white placeholder:text-white border-none focus-visible:!ring-white focus-visible:!ring-opacity-80":
            layoutColor,
        })}
        inputBefore={<div className="w-8 py-1 rounded-l-md" style={{ backgroundColor: input.toHexString() }} />}
        inputAfter={
          <Button
            onClick={() => setShowModal(true)}
            variant={layoutColor ? "naked" : "secondary"}
            className={classNames("-ml-px rounded-l-none z-10", {
              "!text-white border border-white border-opacity-40 hover:border-opacity-80 focus-visible:border-opacity-80 focus-visible:opacity-100":
                layoutColor,
            })}
          >
            Show
          </Button>
        }
        onChange={(ev) => setInput(tinycolor(ev.target.value))}
        onBlur={(ev) => {
          const newColor = tinycolor(ev.target.value)

          if (newColor.isValid()) {
            setInput(newColor)
          } else {
            setInput(DEFAULT_COLOR)
          }
        }}
        onPaste={(ev) => {
          setTimeout(() => {
            const pastedColor = tinycolor((ev.target as HTMLInputElement).value)

            if (pastedColor.isValid()) {
              setInput(pastedColor)
              setShowModal(true)
            } else {
              toast.error("Pasted invalid color")
            }
          }, 200)
        }}
      />

      <Modal show={showModal} onClose={() => setShowModal(false)}>
        {colorConverterConfig.renderModal({ input, widget })}
      </Modal>
    </div>
  )
}
