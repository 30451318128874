import { Disclosure } from "@headlessui/react"
import classNames from "classnames"
import { ANONYMOUS_DASHBOARD_ID } from "components/dashboard/anonymousConfig"
import { useSubscription } from "components/user/useSubscription"
import { useUser } from "lib/supabase/auth"
import { getDashboardSlug } from "lib/utils"
import Link from "next/link"
import { useRouter } from "next/router"
import { FC } from "react"
import { useSelectDashboards } from "reactQuery/dashboard"
import { DashboardSelection } from "./dashboardSelection"

interface Props {
  showDashboardNavigation: boolean
  isDashboardOwner: boolean
  close: () => void
}

export const MobileNavigation: FC<Props> = ({ showDashboardNavigation, isDashboardOwner, close }) => {
  const { asPath } = useRouter()
  const { user } = useUser()
  const { data: dashboards } = useSelectDashboards()
  const { data: subscription } = useSubscription()
  const primaryDashboard = dashboards?.find((d) => d.is_primary)
  const dashboardId = primaryDashboard?.id
  const isAnnonymous = dashboardId === ANONYMOUS_DASHBOARD_ID
  const href =
    isAnnonymous || !dashboardId ? `/dashboard` : `/dashboard/${getDashboardSlug(dashboardId, primaryDashboard.name)}`

  const linkBaseClass = "text-white block px-3 py-2 rounded-md text-base font-medium"
  const linkActiveClass = "bg-indigo-700 text-white font-medium"
  const navigationLinks = [
    { name: "Home", href: "/", current: asPath === "/" },
    { name: "Dashboard", href, current: asPath.includes("/dashboard") },
    { name: "Explore", href: "/explore", current: asPath.includes("/explore") },
  ]

  if (user) {
    if (!subscription) {
      navigationLinks.push({
        name: "Upgrade",
        href: "/upgrade",
        current: asPath === "/upgrade",
      })
    }
  }

  return (
    <div className="px-2 pb-6 space-y-1 bg-indigo-800 shadow-lg relative z-10">
      {navigationLinks?.map((item) => {
        const showDashboardSelection =
          showDashboardNavigation && isDashboardOwner && item.name === "Dashboard" && item.current

        return showDashboardSelection ? (
          <DashboardSelection key={item.name} onChange={close} />
        ) : (
          <Disclosure.Button
            key={item.name}
            as={Link}
            href={item.href}
            onClick={close}
            className={classNames(linkBaseClass, {
              [linkActiveClass]: showDashboardNavigation ? item.current && isDashboardOwner : item.current,
            })}
          >
            {item.name}
          </Disclosure.Button>
        )
      })}
    </div>
  )
}
