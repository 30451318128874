/* eslint-disable @next/next/no-img-element */
import React, { FC, Fragment } from "react"
import { Menu, Transition } from "@headlessui/react"
import {
  UserIcon,
  ArrowLeftOnRectangleIcon,
  SparklesIcon,
  SpeakerWaveIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline"
import { ChevronDownIcon } from "@heroicons/react/20/solid"
import { useRouter } from "next/router"
import { useUser } from "lib/supabase/auth"
import classNames from "classnames"
import { User } from "@supabase/supabase-js"
import { SUBSCRIPTION_CACHE_KEY, useSubscription } from "components/user/useSubscription"
import { useQueryClient } from "react-query"
import { ThemeToggle } from "./themeToggle"

interface Props {
  user: User
}

export const UserMenu: FC<Props> = ({ user }) => {
  const { data: subscription } = useSubscription()
  const { signOut } = useUser()
  const { push, pathname } = useRouter()
  const queryClient = useQueryClient()

  const onSignOut = async () => {
    await push("/")
    queryClient.removeQueries(SUBSCRIPTION_CACHE_KEY)
    signOut()
  }

  return (
    <Menu as={Fragment}>
      {({ open }) => (
        <>
          <Menu.Button
            title="User menu"
            className="group flex items-center transition-all ml-auto px-3 py-2 text-white hover:bg-indigo-700 rounded-md focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:border-indigo-500"
          >
            <div className="text-indigo-300 group-hover:text-white">
              <UserAvatar avatar_url={user.user_metadata.avatar_url} alt={user.user_metadata.full_name} />
            </div>
            <span
              className="hidden sm:block ml-2 font-medium truncate max-w-[150px]"
              title={user.user_metadata.full_name}
            >
              {user.user_metadata.full_name}
            </span>

            <ChevronDownIcon className="h-4 w-4 ml-1 text-indigo-300 group-hover:text-white" />
          </Menu.Button>
          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="origin-left-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white text-sm text-gray-700 ring-1 ring-black ring-opacity-5 dark:bg-gray-900 divide-y divide-gray-200 dark:divide-gray-800/40">
              <div className="py-1">
                {!subscription && (
                  <MenuItem variant="upgrade" onClick={() => push("/upgrade")} active={pathname === "/upgrade"}>
                    <SparklesIcon className="h-4 w-4 mr-2" />
                    Upgrade
                  </MenuItem>
                )}

                <MenuItem onClick={() => push("/account")} active={pathname === "/account"}>
                  <UserIcon className="h-4 w-4 mr-2 text-gray-400 dark:text-gray-500" />
                  Account
                </MenuItem>
                <MenuItem onClick={() => push("/changelog")} active={pathname === "/changelog"}>
                  <SpeakerWaveIcon className="h-4 w-4 mr-2 text-gray-400 dark:text-gray-500" />
                  Changelog
                </MenuItem>
                <div className="px-4 py-3 flex gap-2 text-gray-700 dark:text-gray-300">
                  Theme
                  <ThemeToggle />
                </div>
              </div>
              <div className="py-1">
                <MenuItem onClick={onSignOut}>
                  <ArrowLeftOnRectangleIcon className="h-4 w-4 mr-2 text-gray-400 dark:text-gray-500" />
                  Sign out
                </MenuItem>
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  )
}

const MenuItem: FC<{
  onClick?: () => void
  variant?: "default" | "upgrade"
  active?: boolean
}> = ({ onClick, active: pageActive, variant = "default", children }) => (
  <Menu.Item onClick={onClick} as="button" className="w-full">
    {({ active }) => (
      <span
        className={classNames("inline-flex items-center px-4 py-3 w-full", {
          "font-medium": pageActive,
          "text-gray-700 dark:text-gray-300": variant === "default",
          "text-yellow-600 hover:text-yellow-800 dark:text-yellow-400 dark:hover:text-yellow-500":
            variant === "upgrade",
          "bg-gray-100 dark:bg-gray-800/40": active,
        })}
      >
        {children}
      </span>
    )}
  </Menu.Item>
)

export const UserAvatar: FC<{
  avatar_url: string | null
  alt?: string
}> = ({ avatar_url, alt }) =>
  avatar_url ? (
    <div className="relative w-5 h-5 ml-2">
      <img
        className="absolute -left-2 top-1/2 -translate-y-1/2 h-7 w-7 max-w-none rounded-full"
        src={avatar_url ?? ""}
        alt={alt ?? "User avatar"}
        referrerPolicy="no-referrer"
      />
    </div>
  ) : (
    <UserCircleIcon className="h-5 w-5" />
  )
