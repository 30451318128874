import {
  Session,
  SignInWithOAuthCredentials,
  SignInWithPasswordCredentials,
  SignUpWithPasswordCredentials,
  Subscription,
  User,
} from "@supabase/supabase-js"
import { createContext, FC, useContext, useEffect, useState } from "react"
import { supabase } from "lib/supabase/client"
import { useMutation } from "react-query"
import { BASE_URL } from "lib/utils"
import { PasswordResetModal } from "components/user/resetModal"
import { Database } from "lib/supabase/database.types"
import { queryClient } from "components/providers/reactQueryProvider"

export const useUser = () => {
  const context = useContext(UserContext)
  if (context === undefined) {
    throw new Error(`useUser must be used within a UserContextProvider.`)
  }
  return context
}

export const UserContext = createContext<{
  session: Session | null
  user: User | null
  userDetails: {
    avatar_url: string | null
    created_at: string
    full_name: string
    id: string
    stripe_customer_id: string | null
    updated_at: string | null
  } | null
  signOut: () => void
}>({} as any)

export const UserContextProvider: FC = ({ children }) => {
  const [session, setSession] = useState<Session | null>(null)
  const [showResetModal, setShowResetModal] = useState<boolean>(false)
  const [user, setUser] = useState<User | null>(null)
  const [userDetails, setUserDetails] = useState<Database["public"]["Tables"]["user"]["Row"] | null>(null)
  const getUserDetails = () => supabase.from("user").select("*").single()

  useEffect(() => {
    if (user) {
      Promise.allSettled([getUserDetails()]).then((results) => {
        const result = results[0]
        if (result.status === "fulfilled") {
          if (result.value.status === 401) {
            supabase.auth.refreshSession()
          } else {
            setUserDetails(result.value.data)
          }
        }
      })
    }
  }, [user])

  useEffect(() => {
    let subscription: Subscription
    ;(async () => {
      const { data } = await supabase.auth.getSession()
      setSession(data.session)
      setUser(data.session?.user ?? null)
      const { data: authData } = supabase.auth.onAuthStateChange(async (event, session) => {
        subscription = authData.subscription
        setSession(session)
        setUser(session?.user ?? null)

        if (event === "PASSWORD_RECOVERY") {
          setShowResetModal(true)
        }
      })
    })()

    return () => {
      subscription?.unsubscribe()
    }
  }, [])

  const value = {
    session,
    user,
    userDetails,
    signOut: () => {
      supabase.auth.signOut()
      queryClient.clear()
    },
  }
  return (
    <UserContext.Provider value={value}>
      <PasswordResetModal show={showResetModal} setShow={setShowResetModal} />
      {children}
    </UserContext.Provider>
  )
}

export const useSignIn = () =>
  useMutation<void, Error, SignInWithPasswordCredentials>(async (credentials) => {
    const { error } = await supabase.auth.signInWithPassword(credentials)

    if (error) {
      throw error
    }
  })

export const useSignInWithOAuth = () =>
  useMutation<void, Error, SignInWithOAuthCredentials>(async (credentials) => {
    const { error } = await supabase.auth.signInWithOAuth({
      ...credentials,
      options: {
        ...credentials.options,
        redirectTo: `${BASE_URL}/dashboard`,
      },
    })

    if (error) {
      throw error
    }
  })

// Email sign up
export const useSignUp = () =>
  useMutation<void, Error, SignUpWithPasswordCredentials>(async (credentials) => {
    const { error } = await supabase.auth.signUp(credentials)

    if (error) {
      throw error
    }
  })

// Email reset password
export const useResetPassword = () =>
  useMutation<void, Error, string>(async (email) => {
    const { error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: `${BASE_URL}/dashboard`,
    })

    if (error) {
      throw error
    }
  })

// Update user password
export const useUpdateUserPassword = () =>
  useMutation<void, Error, { accessToken?: string; newPassword: string }>(async ({ accessToken, newPassword }) => {
    if (!accessToken) {
      throw new Error("No accessToken set")
    }

    const { error } = await supabase.auth.updateUser({ password: newPassword })

    if (error) {
      throw error
    }
  })
