import React, { FC } from "react"
import { useRouter } from "next/router"
import { Link } from "components/core/link"

interface Props {
  showText?: boolean
}

export const Logo: FC<Props> = ({ showText = true }) => {
  const { pathname } = useRouter()
  const isHomePage = pathname === "/"
  const HeadingTag = `h${isHomePage ? 1 : 2}` as keyof JSX.IntrinsicElements

  return (
    <Link to="/" className="flex items-center !text-white hover:!text-indigo-100 py-4 -my-3 px-4 -ml-3.5">
      <svg
        className="w-6 h-6"
        viewBox="0 0 103 103"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-label="Frontend Toolkit"
      >
        <path fill="#818CF8" d="M60 0h43v19H60zm13 42h30v19H73zM34 84h69v19H34z" />
        <path d="M63 61H23.997v42H0V0h50v18.985H24V42h39v19z" fill="#fff" />
      </svg>

      {showText && (
        <HeadingTag className="hidden sm:block text-lg ml-4 font-medium leading-7">Frontend Toolkit</HeadingTag>
      )}
    </Link>
  )
}
