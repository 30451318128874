import { FC, ReactNode } from "react"

interface Props {
  title: ReactNode
  subtitle?: ReactNode
  headingTag?: string
}

export const Title: FC<Props> = ({ title, subtitle, headingTag = "h1" }) => {
  const Heading = headingTag as keyof JSX.IntrinsicElements

  return (
    <div className="mb-4">
      <Heading className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-200">{title}</Heading>
      {subtitle && <div className="mt-1 text-sm text-gray-500 dark:text-gray-400">{subtitle}</div>}
    </div>
  )
}
