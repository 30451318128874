import { Button } from "components/core/button"
import { InputLabel } from "components/core/inputLabel"
import { Title } from "components/core/modal/title"
import { Formik } from "formik"
import { useUser } from "lib/supabase/auth"
import { useRouter } from "next/router"
import { useInsertDashboard } from "reactQuery/dashboard"
import React, { FC } from "react"
import { Dashboard } from "domainModels/dashboard"
import { TOAST_DASHHBOARD_ADD } from "components/dashboard/toastConfig"
import toast from "react-hot-toast"
import { getDashboardSlug } from "lib/utils"

interface Props {
  onClose: () => void
}

export const AddDashboardModal: FC<Props> = ({ onClose }) => {
  const { mutateAsync: insertDashboard } = useInsertDashboard()
  const { user, userDetails } = useUser()
  const { replace } = useRouter()

  return (
    <div>
      <Title title="Add new dashboard" />

      <Formik
        initialValues={{ name: "" }}
        validate={async (values) => {
          const errors: {
            [key: string]: string
          } = {}

          if (!values.name || (values.name && values.name.length < 3)) {
            errors.name = "Your dashboard name should contain at least 3 characters"
          }

          return errors
        }}
        onSubmit={async (values, { resetForm }) => {
          if (user && userDetails && values.name) {
            const dashboard = await toast.promise(
              insertDashboard({
                dashboard: new Dashboard({
                  user_id: user.id,
                  name: values.name,
                  owner_name: userDetails.full_name,
                  owner_avatar: userDetails.avatar_url,
                  is_private: false,
                  is_primary: false,
                  is_forked: false,
                  is_password_protected: false,
                }),
              }),
              TOAST_DASHHBOARD_ADD
            )

            if (dashboard?.id) {
              replace("/dashboard/[slug]", `/dashboard/${getDashboardSlug(dashboard.id, user.id)}`)
            }

            resetForm()
            onClose()
          }
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <div className="space-y-6 my-8 max-w-md">
              <InputLabel
                id="name"
                type="text"
                label="Name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                errors={errors.name && touched.name ? errors.name : null}
              />
            </div>
            <div className="mt-5 md:flex md:flex-row-reverse">
              <Button
                type="submit"
                disabled={isSubmitting}
                variant="primary"
                className="justify-center w-full md:w-auto md:ml-3"
              >
                Add dashboard
              </Button>
              <Button onClick={onClose} className="justify-center w-full md:w-auto mt-4 md:mt-0">
                Cancel
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}
