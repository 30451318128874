export const tryBase64Encode = (code: string) => {
  try {
    if (typeof window !== "undefined") {
      return window.btoa(code)
    } else {
      return Buffer.from(code, "binary").toString("base64")
    }
  } catch (err) {
    return ""
  }
}

export const tryBase64Decode = (code: string) => {
  try {
    if (typeof window !== "undefined") {
      return window.atob(code)
    } else {
      return Buffer.from(code, "base64").toString("binary")
    }
  } catch (err) {
    return ""
  }
}
