export const TOAST_DASHBOARD_UPDATE = {
  loading: "Updating...",
  success: "Dashboard updated!",
  error: "Could not update dashboard :/",
}

export const TOAST_DASHHBOARD_ADD = {
  loading: "Adding...",
  success: "Dashboard added!",
  error: "Could not add dashboard :/",
}

export const TOAST_DASHBOARD_REMOVE = {
  loading: "Removing...",
  success: "Dashboard removed!",
  error: "Could not remove dashboard :/",
}

export const TOAST_GROUP_UPDATE = {
  loading: "Updating...",
  success: "Group updated!",
  error: "Could not update group :/",
}

export const TOAST_NOTE_UPDATE = {
  loading: "Updating...",
  success: "Note updated!",
  error: "Could not update note :/",
}

export const TOAST_CUSTOM_WIDGET_UPDATE = {
  loading: "Updating...",
  success: "Widget updated!",
  error: "Could not update widget :/",
}

export const TOAST_WIDGET_LOCK = {
  loading: "Locking...",
  success: "Widget locked!",
  error: "Could not lock widget :/",
}
export const TOAST_WIDGET_UNLOCK = {
  loading: "Unlocking...",
  success: "Widget unlocked!",
  error: "Could not unlock widget :/",
}
