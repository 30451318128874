import { Loading } from "components/core/modal/loading"
import { WidgetConfig, WidgetModalProps } from "components/dashboard/grid/config"
import { ColorConverterPreview } from "components/dashboard/widgets/colorConverter/colorConverterPreview"
import dynamic from "next/dynamic"
import React from "react"

const ColorConvertersModal = dynamic(() => import("./colorConverterModal"), { loading: () => <Loading /> })

export const colorConverterConfig: WidgetConfig = {
  slug: "color-converter",
  renderPreview: (widget) => <ColorConverterPreview widget={widget} />,
  renderModal: (props?: WidgetModalProps<tinycolor.Instance>) => <ColorConvertersModal {...props} />,
  icon: (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26.7108 4.27973C26.1135 3.68087 25.1487 4.16485 24.4262 4.60277C23.6823 5.0537 22.7033 5.78059 21.5161 6.7633C18.9854 8.85819 16.1069 11.6033 13.7802 13.9349L13.778 13.9336C12.4706 15.2561 11.2271 16.5499 9.77332 18.2348C9.10219 18.1334 8.34121 18.2197 7.63522 18.5164L7.61905 18.5235C6.16591 19.1795 5.21658 20.6377 5.20054 22.2385V22.2453C5.20054 24.0209 4.89206 25.4485 4.5874 25.941L4 26.8903L5.11173 26.9703C5.38449 26.9899 5.67029 27 5.96493 27C8.12941 27 10.7689 26.4553 12.1921 25.1468L12.196 25.1432C12.9298 24.458 13.2864 23.51 13.2864 22.2453C13.2864 22.0941 13.2773 21.9441 13.2594 21.7928C14.936 20.3398 16.2272 19.1009 17.5714 17.7786L17.5689 17.7743C20.3146 15.023 22.7621 12.1288 24.5153 9.70977C25.3972 8.49297 26.0812 7.41955 26.4932 6.60561C26.9157 5.77104 27.2719 4.84199 26.7108 4.27973V4.27973ZM11.2791 24.1535C10.3237 25.03 8.2268 25.6221 6.14237 25.6499C6.39699 24.7538 6.54784 23.536 6.54811 22.2489C6.5601 21.1767 7.19391 20.2004 8.16445 19.7585C8.74075 19.5185 9.32011 19.4818 9.93316 19.6524C10.0072 19.6524 10.6296 19.8086 11.1522 20.3324C12.2698 21.4522 12.1105 23.3749 11.2791 24.1535V24.1535ZM12.8308 20.3748C12.642 20.0066 12.3972 19.6702 12.1052 19.3774C11.8246 19.0962 11.5122 18.8647 11.1709 18.6834C12.0475 17.6844 12.9983 16.6576 13.9691 15.6618C14.3239 15.9171 14.6574 16.2009 14.9661 16.5104L14.9727 16.517C15.3014 16.8379 15.5985 17.1897 15.8597 17.5678C14.8418 18.5574 13.8274 19.4968 12.8308 20.3748V20.3748ZM23.0629 9.40866C21.3248 11.742 19.0602 14.3556 16.8293 16.605C16.5541 16.2295 16.2486 15.8774 15.9158 15.5523C15.6072 15.2435 15.2776 14.9566 14.9292 14.6938C17.4152 12.2089 19.8723 9.91906 21.8632 8.23269C23.8853 6.51985 24.9583 5.82443 25.5011 5.54236C25.0321 6.67993 23.7982 8.42162 23.0629 9.40866V9.40866Z"
        fill="currentColor"
      />
    </svg>
  ),
}
