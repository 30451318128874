import React, { FC, useState } from "react"
import { SvgFileUpload } from "components/core/svgFileUpload"
import { Modal } from "components/core/modal/modal"
import { Widget } from "domainModels/widget"
import { svgConverterConfig } from "components/dashboard/widgets/svgConverter/svgConverterConfig"

interface Props {
  widget: Widget
}

export const SvgConverterPreview: FC<Props> = ({ widget }) => {
  const [input, setInput] = useState<string>()

  return (
    <div className="relative h-full">
      <SvgFileUpload id="svg2JsxPreview" onChange={setInput} />
      <Modal show={!!input} className="max-w-[90vw]" onClose={() => setInput(undefined)}>
        {svgConverterConfig.renderModal({ input, widget })}
      </Modal>
    </div>
  )
}
