import { supabase } from "lib/supabase/client"
import { Dashboard } from "domainModels/dashboard"
import { SupabaseClient } from "@supabase/supabase-js"

export const selectDashboard = async (dashboardId: number, supabaseClient?: SupabaseClient) => {
  const client = supabaseClient ?? supabase
  const { data: dashboard } = await client.from("dashboard").select().eq("id", dashboardId).single()
  return dashboard
}

export const selectDashboards = async (userId: string) => {
  const { data } = await supabase.from("dashboard").select().eq("user_id", userId).throwOnError()
  return data?.map((record) => new Dashboard(record)) ?? null
}

export const updateDashboard = async (dashboard: Dashboard) => {
  if (dashboard.id === null) {
    throw new Error("Dashboard instance has no id. Cannot perform update.")
  }
  const { count } = await supabase
    .from("dashboard")
    .update(
      {
        name: dashboard.name,
        is_primary: dashboard.is_primary,
        is_forked: dashboard.is_forked,
        owner_name: dashboard.owner_name,
        owner_avatar: dashboard.owner_avatar,
        updated_at: dashboard.updated_at ?? new Date().toISOString(),
      },
      { count: "exact" }
    )
    .eq("id", dashboard.id)
    .throwOnError()
  if (count === 0) {
    throw new Error("No dashboard rows were updated.")
  }
}

export const insertDashboard = async (dashboard: Dashboard) => {
  if (dashboard.id !== null) {
    throw new Error("No manual PRIMARY KEY insertion for type SERIAL.")
  }
  const { data, error, status } = await supabase
    .from("dashboard")
    .insert({
      name: dashboard.name,
      is_primary: dashboard.is_primary,
      is_forked: dashboard.is_forked,
      owner_name: dashboard.owner_name,
      owner_avatar: dashboard.owner_avatar,
    })
    .select()
    .single()

  if (error && status !== 406) {
    throw new Error(error.message)
  }

  if (!data) {
    throw new Error("No recond was returned for dashboard insertion.")
  }

  return new Dashboard(data)
}

export const deleteDashboard = async (dashboard: Dashboard) => {
  if (dashboard.id === null) {
    throw new Error("Dashboard instance has no id. Cannot perform delete.")
  }

  const { count } = await supabase.from("dashboard").delete({ count: "exact" }).eq("id", dashboard.id).throwOnError()

  if (count === 0) {
    throw new Error("No dashboard rows were deleted.")
  }
}

export const selectPublicDashboardsIds = async (limit = 30) => {
  const { data, error, status } = await supabase.from("dashboard").select("id").eq("is_private", false).limit(limit)

  if (error && status !== 406) {
    throw new Error(error.message)
  }
  return data
}

export const selectPublicDashboard = async (id: number) => {
  const { data, error, status } = await supabase
    .from("dashboard")
    .select()
    .eq("is_private", false)
    .eq("id", id)
    .single()

  if (error && status !== 406) {
    throw new Error(error.message)
  }
  return data ? new Dashboard(data) : null
}

export const selectPublicDashboards = async (limit = 300) => {
  const { data, error, status } = await supabase
    .from("dashboard")
    .select()
    // @ts-ignore
    .eq("is_private", false)
    .limit(limit)
    .order("updated_at", { ascending: false })

  if (error && status !== 406) {
    throw new Error(error.message)
  }
  return data ? data.map((d) => new Dashboard(d)) : null
}
