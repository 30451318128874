import classNames from "classnames"
import { DetailedHTMLProps, FC, HTMLAttributes } from "react"

interface Props extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  className?: string
  showHoverEffect?: boolean
}

export const Card: FC<Props> = ({ children, className, showHoverEffect, ...rest }) => (
  <div className="relative z-10 w-full h-full">
    <div
      className={classNames(
        "relative bg-white dark:bg-gray-900 bg-opacity-95 dark:bg-opacity-70",
        "before:content-[''] before:absolute before:rounded-[inherit] before:-inset-px before:-z-10 before:bg-gradient-to-br before:from-[#cccef8]/60 before:to-[#e0c9fa]/60 before:dark:from-[#2d3367] before:dark:to-[#412e69]",
        className,
        {
          "after:content-[''] after:absolute after:rounded-[inherit] after:-inset-px after:-z-10 after:ease-in-out after:transition-all after:duration-300 after:opacity-0 after:transform-gpu":
            showHoverEffect,
          "hover:after:opacity-100 hover:after:bg-indigo-300 hover:after:dark:bg-indigo-800": showHoverEffect,
        }
      )}
      {...rest}
    >
      {children}
    </div>
  </div>
)
