import { useSubscription } from "components/user/useSubscription"
import { useUser } from "lib/supabase/auth"
import { useCurrentDashboard, useSelectDashboards } from "reactQuery/dashboard"
import { useSelectWidgets } from "reactQuery/widget"
import { FeatureName, FEATURES } from "./features"

export const useFeature = (feature: FeatureName) => {
  const matchedFeature = FEATURES.find((f) => f.id === feature)
  const { user } = useUser()
  const { data: subscription, isLoading: isLoadingSubscriptions } = useSubscription()
  const { data: dashboards, isLoading: isLoadingDashboards } = useSelectDashboards()
  const { data: currentDashboard, isLoading: isLoadingDashboard } = useCurrentDashboard()
  const { data: widgets, isLoading: isLoadingWidgets } = useSelectWidgets(currentDashboard?.id)
  const isLoading = isLoadingSubscriptions || isLoadingDashboards || isLoadingDashboard || isLoadingWidgets

  if (!matchedFeature) {
    return {
      enabled: false,
      exhausted: false,
      isLoading,
    }
  }

  return {
    enabled: matchedFeature.isEnabled({ user, subscription }),
    exhausted: matchedFeature.isExhausted({
      user,
      subscription,
      dashboards,
      widgets,
    }),
    isLoading,
  }
}
