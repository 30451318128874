import { useUser } from "lib/supabase/auth"
import { BASE_URL } from "lib/utils"
import { useQuery } from "react-query"
import { Stripe } from "stripe"

export const SUBSCRIPTION_CACHE_KEY = "subscription"

export const useSubscription = () => {
  const { session } = useUser()

  return useQuery<Stripe.Subscription | null, Error>(
    SUBSCRIPTION_CACHE_KEY,
    async () => {
      try {
        const token = session?.access_token!
        const headers = new Headers({ "Content-Type": "application/json", token: token })
        const url = `${BASE_URL}/api/stripe/subscription`
        const res = await fetch(url, { headers })

        if (res.status !== 200) {
          return null
        }

        const json = await res.json()

        return json
      } catch (err) {
        return null
      }
    },
    {
      enabled: !!session?.access_token,
      retry: false,
    }
  )
}
