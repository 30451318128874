import React, { FC, useState } from "react"
import { Hydrate, QueryClient, QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
    },
  },
})

interface Props {
  pageProps: any
}

export const ReactQueryProvider: FC<Props> = ({ children, pageProps }) => {
  const [localQqueryClient] = useState(() => queryClient)

  return (
    <QueryClientProvider client={localQqueryClient}>
      <Hydrate state={pageProps?.dehydratedState}>{children}</Hydrate>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </QueryClientProvider>
  )
}
