import { Alert } from "components/core/alert"
import { Button } from "components/core/button"
import { InputLabel } from "components/core/inputLabel"
import { Modal } from "components/core/modal/modal"
import { Title } from "components/core/modal/title"
import { Form, Formik } from "formik"
import { useUpdateUserPassword, useUser } from "lib/supabase/auth"
import React, { FC } from "react"
import toast from "react-hot-toast"

interface Props {
  show: boolean
  setShow: (val: boolean) => void
}

export const PasswordResetModal: FC<Props> = ({ show, setShow }) => {
  const { session } = useUser()
  const { mutateAsync: updateUserPassword, isLoading: isUpdatingUserPassword, error } = useUpdateUserPassword()
  const onClose = () => setShow(false)

  return (
    <Modal show={show} className="max-w-2xl" onClose={onClose}>
      <Title title="Reset Password" />

      <Formik
        initialValues={{ password: "" }}
        validate={async (values) => {
          const errors: {
            [key: string]: string
          } = {}

          if (values.password.length <= 6) {
            errors.password = "Your password must at latest contain 6 characters"
          }

          return errors
        }}
        onSubmit={async (values) => {
          await updateUserPassword({
            newPassword: values.password,
            accessToken: session?.access_token,
          })

          toast.success("The password has been successfully reset!")
          onClose()
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
          return (
            <Form className="relative" onSubmit={handleSubmit} autoComplete="off">
              <InputLabel
                type="password"
                id="password"
                label="New password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                errors={errors.password && touched.password ? errors.password : null}
              />

              {error && (
                <Alert type="error" className="mt-6">
                  {error.message}
                </Alert>
              )}

              <div className="text-right mt-6">
                <Button type="submit" variant="primary" disabled={isSubmitting} loading={isUpdatingUserPassword}>
                  Update password
                </Button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}
