import React, { FC } from "react"
import { Toaster } from "react-hot-toast"
import { Footer } from "components/layout/footer"
import { Header } from "components/layout/header/header"
import { useColorMode } from "components/core/colorModeContent"
import { HeaderDashboard } from "./header/headerDashboard"
import { useRouter } from "next/router"

export const Layout: FC = ({ children }) => {
  const { pathname } = useRouter()
  const { darkModeEnabled } = useColorMode()
  const isDashboard = pathname.includes("/dashboard")

  return (
    <>
      <div className="flex flex-col justify-between h-full">
        <div className="flex-1 bg-gray-100 dark:bg-gray-900 ">
          {isDashboard ? <HeaderDashboard /> : <Header />}
          {children}
        </div>
        {!isDashboard && <Footer />}
      </div>
      <Toaster
        position="bottom-right"
        toastOptions={{
          className: "text-sm",
          style: {
            padding: "7px 14px",
            background: darkModeEnabled ? "#1F2937" : "#fff",
            color: darkModeEnabled ? "#9ca3af" : "#6b7280",
          },
        }}
      />
    </>
  )
}
