import { THEME_STORAGE_EVENT } from "components/layout/header/themeToggle"
import { FC, ReactNode, useEffect, useState } from "react"

interface Props {
  dark: ReactNode
  light: ReactNode
}

export const ColorModeContent: FC<Props> = ({ dark, light }) => {
  const { darkModeEnabled } = useColorMode()

  if (darkModeEnabled === undefined) {
    return null
  }

  return <>{darkModeEnabled ? dark : light}</>
}

export const useColorMode = () => {
  const [darkModeEnabled, setDarkModeEnabled] = useState<boolean>()
  const onStorageChange = () => {
    setDarkModeEnabled(localStorage.theme === "dark")
  }

  useEffect(() => {
    setDarkModeEnabled(localStorage.theme === "dark")
    window.addEventListener(THEME_STORAGE_EVENT, onStorageChange)

    return () => {
      window.removeEventListener(THEME_STORAGE_EVENT, onStorageChange)
    }
  }, [])

  return { darkModeEnabled }
}
