import { Layout } from "domainModels/layout"
import { supabase } from "lib/supabase/client"

export const updateLayout = async (layout: Layout) => {
  if (layout.id === null) {
    throw new Error("Layout instance has no id. Cannot perform update.")
  }
  if (typeof layout.id === "string") {
    throw new Error("Layout instance has no id. Cannot perform update.")
  }
  const { count } = await supabase
    .from("layout")
    .update(
      {
        // @ts-ignore
        xs: layout.xs,
        // @ts-ignore
        md: layout.md,
        // @ts-ignore
        lg: layout.lg,
        // @ts-ignore
        xl: layout.xl,
        // @ts-ignore
        xxl: layout.xxl,
      },
      { returning: "minimal", count: "exact" }
    )
    .eq("id", layout.id)
    .throwOnError()
  if (count === 0) {
    throw new Error("No layout rows were updated.")
  }
}

export const insertLayout = async (layout: Layout) => {
  if (layout.id !== null) {
    throw new Error("No manual PRIMARY KEY insertion for type SERIAL.")
  }
  const { data, error, status } = await supabase
    .from("layout")
    .insert({
      // @ts-ignore
      xs: layout.xs,
      // @ts-ignore
      md: layout.md,
      // @ts-ignore
      lg: layout.lg,
      // @ts-ignore
      xl: layout.xl,
      // @ts-ignore
      xxl: layout.xxl,
    })
    .select()
    .single()
  if (error && status !== 406) {
    throw new Error(error.message)
  }
  if (!data) {
    throw new Error("No record returned for layout insertion.")
  }
  return new Layout(data)
}
