import { Widget } from "domainModels/widget"
import { CURRENT_ENVIRONMENT, Environments } from "lib/utils"

export const GA_TRACKING_ID = "G-RKG743L0FZ"

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const trackPageview = () => {
  if (window.gtag && CURRENT_ENVIRONMENT === Environments.PRODUCTION) {
    window.gtag("event", "page_view", {
      page_title: document.title,
      page_location: location.href,
      page_path: location.pathname,
      send_to: GA_TRACKING_ID,
    })
  }
}

export const trackCreateCustomWidget = (customWidget: Widget) => {
  if (window.gtag && CURRENT_ENVIRONMENT === Environments.PRODUCTION) {
    window.gtag("event", "custom_widget_create", {
      name: customWidget.information.name,
      description: customWidget.information.description,
      url: customWidget.information.external_url,
      keywords: customWidget.information.keywords?.join(","),
    })
  }
}

export const trackCustomWidgetView = (customWidget: Widget) => {
  if (window.gtag && CURRENT_ENVIRONMENT === Environments.PRODUCTION) {
    window.gtag("event", "custom_widget_view", {
      name: customWidget.information.name,
      description: customWidget.information.description,
      url: customWidget.information.external_url,
      keywords: customWidget.information.keywords?.join(","),
    })
  }
}

export const trackLegacyDashboard = (layouts: any, removedWidgets: any, customWidgets: any, groupedWidgets: any) => {
  if (window.gtag && CURRENT_ENVIRONMENT === Environments.PRODUCTION) {
    window.gtag("event", "legacy_dashboard", {
      layouts,
      removedWidgets,
      customWidgets,
      groupedWidgets,
    })
  }
}
