import React, { FC, Fragment, useState } from "react"
import { Transition } from "@headlessui/react"
import { Listbox } from "@headlessui/react"
import classNames from "classnames"
import { Button, ButtonProps } from "components/core/button"
import { LockClosedIcon, PlusIcon, Cog6ToothIcon, Squares2X2Icon } from "@heroicons/react/24/outline"
import { ChevronDownIcon } from "@heroicons/react/20/solid"
import { Tooltip } from "components/core/tooltip"
import { Modal } from "components/core/modal/modal"
import { AddDashboardModal } from "components/layout/header/addDashboardModal"
import { useRouter } from "next/router"
import { useFeature } from "lib/features/useFeature"
import { FeatureName } from "lib/features/features"
import { EditDashboardModal } from "./editDashboardModal"
import { Link } from "components/core/link"
import { ANONYMOUS_DASHBOARD_ID } from "components/dashboard/anonymousConfig"
import { useSubscription } from "components/user/useSubscription"
import { Dashboard } from "domainModels/dashboard"
import { useCurrentDashboard, useSelectDashboards } from "reactQuery/dashboard"
import { getDashboardSlug } from "lib/utils"
import { useUser } from "lib/supabase/auth"

interface Props {
  onChange?: (val: Dashboard) => void
}

export const DashboardSelection: FC<Props> = ({ onChange }) => {
  const { replace } = useRouter()
  const { user } = useUser()
  const { data: dashboard } = useCurrentDashboard()
  const { data: dashboards } = useSelectDashboards()
  const [showAddDashboard, setShowAddDashboard] = useState(false)
  const [editDashboardId, setEditDashboardId] = useState<number>()
  const [showEditDashboard, setShowEditDashboard] = useState(false)
  const menuItemClass =
    "inline-flex items-center justify-between text-left text-gray-700 dark:text-gray-300 w-full bg-white hover:bg-gray-100 dark:bg-gray-900 dark:hover:bg-gray-800/40"

  return (
    <>
      <Listbox
        value={dashboard}
        onChange={(val) => {
          if (val?.id) {
            onChange?.(val)

            if (val.id === ANONYMOUS_DASHBOARD_ID) {
              replace("/dashboard", `/dashboard`)
            } else if (user) {
              replace("/dashboard/[slug]", `/dashboard/${getDashboardSlug(val.id, user.id)}`)
            }
          }
        }}
      >
        <div className="relative">
          <Listbox.Button className="group flex items-center transition-all px-3 py-1 md:py-2 text-white hover:bg-indigo-700 rounded-md focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:border-indigo-500">
            <Squares2X2Icon className="h-5 w-5 mr-2 text-indigo-300 group-hover:text-white" />
            <span className="md:text-sm font-medium truncate max-w-[150px]">{dashboard?.name}</span>
            <ChevronDownIcon className="h-4 w-4 ml-1 text-indigo-300 group-hover:text-white" />
          </Listbox.Button>

          <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Listbox.Options className="absolute min-w-[200px] z-10 mt-1 bg-white dark:bg-gray-900 shadow-lg max-h-[70vh] overflow-auto rounded-md py-1 ring-1 ring-black ring-opacity-5 text-sm divide-y divide-gray-200 dark:divide-gray-800/40">
              <li className="py-4 px-4 uppercase text-xs text-gray-500 dark:text-gray-400 font-medium">
                Your Dashboards
              </li>
              {dashboards?.map((dashboard) => (
                <Listbox.Option
                  key={dashboard.id}
                  className={() => classNames(menuItemClass, "cursor-pointer px-4 py-2")}
                  value={dashboard}
                >
                  {({ selected }) => (
                    <div className="flex w-full justify-between text-sm">
                      <div
                        className={classNames(
                          selected ? "font-semibold dark:text-white" : "font-normal",
                          "flex items-center truncate max-w-[300px] mr-5"
                        )}
                      >
                        {dashboard.name}
                      </div>

                      <Button
                        size="small"
                        className="ml-2"
                        onClick={(ev) => {
                          ev.stopPropagation()
                          if (dashboard.id) {
                            setEditDashboardId(dashboard.id)
                            setShowEditDashboard(true)
                          }
                        }}
                      >
                        <Cog6ToothIcon className="w-4 h-4" />
                      </Button>
                    </div>
                  )}
                </Listbox.Option>
              ))}
              <li className={classNames(menuItemClass)}>
                <AddDashboardButton setShowAddDashboard={setShowAddDashboard} />
              </li>
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>

      <Modal className="max-w-2xl" show={showAddDashboard} onClose={() => setShowAddDashboard(false)}>
        <AddDashboardModal onClose={() => setShowAddDashboard(false)} />
      </Modal>

      <Modal
        className="max-w-2xl"
        show={showEditDashboard}
        onClose={() => setShowEditDashboard(false)}
        afterLeave={() => setEditDashboardId(undefined)}
      >
        {editDashboardId && (
          <EditDashboardModal dashboardId={editDashboardId} onClose={() => setShowEditDashboard(false)} />
        )}
      </Modal>
    </>
  )
}

export const AddDashboardButton: FC<{ setShowAddDashboard: (val: boolean) => void }> = ({ setShowAddDashboard }) => {
  const { data: subscription } = useSubscription()
  const { enabled, exhausted } = useFeature(FeatureName.DASHBOARD_AMOUNT)
  const buttonProps: ButtonProps = {
    disabled: !enabled || exhausted,
    variant: "naked",
    size: "small",
    className: "py-2.5 px-3 font-normal w-full",
    onClick: () => setShowAddDashboard(true),
  }

  if (!enabled) {
    return (
      <Tooltip
        interactive
        content={
          <>
            To add multiple dashboards you need to sign up.{" "}
            <Link to="/auth/registration" className="block">
              Sign up for free
            </Link>
          </>
        }
      >
        <Button {...buttonProps}>
          <span className="flex items-center">
            <LockClosedIcon className="w-4 h-4 mr-2" />
            Add
          </span>
        </Button>
      </Tooltip>
    )
  } else if (exhausted) {
    return (
      <Tooltip
        interactive
        content={
          <>
            You can&apos;t add more dashboards with your current plan.{" "}
            {!subscription && (
              <Link to="/upgrade" className="block">
                Upgrade now
              </Link>
            )}
          </>
        }
      >
        <Button {...buttonProps}>
          <span className="flex items-center">
            <LockClosedIcon className="w-4 h-4 mr-2" />
            Add
          </span>
        </Button>
      </Tooltip>
    )
  }

  return (
    <Button {...buttonProps}>
      <PlusIcon className="w-4 h-4 mr-2" /> Add
    </Button>
  )
}
