import React, { FC } from "react"
import { Logo } from "components/layout/logo"
import { Search } from "components/layout/header/search"
import { useUser } from "lib/supabase/auth"
import { DashboardSelection } from "components/layout/header/dashboardSelection"
import { UserMenu } from "./userMenu"
import { Link } from "components/core/link"
import classNames from "classnames"
import { ANONYMOUS_DASHBOARD_ID } from "components/dashboard/anonymousConfig"
import { useIsDashboardOwner, useSelectDashboards } from "reactQuery/dashboard"
import { MobileNavigation } from "./mobileNavigation"
import { Disclosure, Menu } from "@headlessui/react"
import { Bars3Icon, LockClosedIcon, PlusIcon, XMarkIcon } from "@heroicons/react/24/outline"
import { ChevronRightIcon } from "@heroicons/react/20/solid"
import { getDashboardSlug } from "lib/utils"
import { WidgetAddMenu } from "components/dashboard/widgets/shared/widgetAddMenu"
import { Tooltip } from "components/core/tooltip"
import { FeatureName, WIDGETS_FREE_MAX_AMOUNT } from "lib/features/features"
import { useFeature } from "lib/features/useFeature"

const headerLinkClass =
  "px-3 py-2 shadow-sm text-sm font-medium rounded-full !text-white bg-indigo-700 hover:bg-indigo-600"

export const HeaderDashboard: FC = () => {
  const { user } = useUser()
  const isDashboardOwner = useIsDashboardOwner()
  const { exhausted: widgetsExhausted, isLoading } = useFeature(FeatureName.WIDGETS_AMOUNT)

  return (
    <Disclosure as="nav" className="bg-gray-800">
      {({ open, close }) => (
        <>
          <div className="relative z-40 bg-indigo-800 px-4 sm:px-6 lg:px-12 py-2.5 flex justify-between items-center">
            <div className="flex items-center flex-1">
              <Logo showText={false} />
              {isDashboardOwner && (
                <div className="hidden sm:block">
                  <Search />
                </div>
              )}
            </div>

            {isDashboardOwner && !isLoading && (
              <div className="flex gap-4 items-center">
                <AddWidget widgetsExhausted={widgetsExhausted} />
                <div className="hidden md:block">
                  <DashboardSelection />
                </div>
              </div>
            )}
            {!isDashboardOwner && !isLoading && <DefaultDashboardMiddleNavigation />}

            <div className="flex items-center justify-end flex-1">
              <div className="relative text-right text-sm">
                {user ? (
                  <UserMenu user={user} />
                ) : (
                  <Link to="/auth/login" className={classNames(headerLinkClass, "mr-2 md:mr-0 whitespace-nowrap")}>
                    Sign in
                    <ChevronRightIcon className="hidden md:inline-block ml-0.5 h-4 w-4 -mr-2 text-indigo-300" />
                  </Link>
                )}
              </div>

              <Disclosure.Button className="md:hidden p-1.5 text-white hover:bg-indigo-700 rounded-md focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:border-indigo-500">
                <span className="sr-only">Open main menu</span>
                {open ? (
                  <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                ) : (
                  <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                )}
              </Disclosure.Button>
            </div>
          </div>

          <Disclosure.Panel className="md:hidden">
            <MobileNavigation close={close} isDashboardOwner={isDashboardOwner} showDashboardNavigation />
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}

const DefaultDashboardMiddleNavigation: FC = () => {
  const { data: dashboards } = useSelectDashboards()
  const { user } = useUser()
  const primaryDashboard = dashboards?.find((d) => d.is_primary)
  const dashboardId = primaryDashboard?.id
  const isAnnonymous = dashboardId === ANONYMOUS_DASHBOARD_ID
  const href =
    isAnnonymous || !dashboardId || !user ? `/dashboard` : `/dashboard/${getDashboardSlug(dashboardId, user.id)}`

  return (
    <Link to={href} className={classNames(headerLinkClass, "mr-2 inline-flex items-center")}>
      Go to your Dashboard
    </Link>
  )
}

const AddWidget: FC<{ widgetsExhausted: boolean }> = ({ widgetsExhausted }) => {
  const { user } = useUser()

  return (
    <WidgetAddMenu
      trigger={
        <Tooltip
          disabled={!widgetsExhausted}
          interactive
          content={
            user ? (
              <div className="w-52">
                You can&apos;t add more widgets with your current plan.{" "}
                <Link to="/upgrade" className="block">
                  Upgrade now
                </Link>
              </div>
            ) : (
              <div className="w-52">
                You can only have {WIDGETS_FREE_MAX_AMOUNT} widgets on a dashboard. To add more widgets you need to sign
                up for a pro plan.{" "}
                <Link to="/auth/registration" className="block">
                  Sign up
                </Link>
              </div>
            )
          }
        >
          <Menu.Button
            disabled={widgetsExhausted}
            title="Add new widget"
            className="group flex items-center transition-all px-3 py-2 text-white hover:bg-indigo-700 rounded-md focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:border-indigo-500"
          >
            {widgetsExhausted ? (
              <LockClosedIcon className="w-4 h-4 text-indigo-300" />
            ) : (
              <PlusIcon className="h-5 w-5 text-indigo-300 group-hover:text-white" />
            )}

            <span className="ml-1.5 text-sm font-medium">Add widget</span>
          </Menu.Button>
        </Tooltip>
      }
    />
  )
}
