import { Spinner } from "components/core/spinner"
import { FC, useEffect, useState } from "react"

interface Props {
  title: string
  externalUrl: string | null
  className?: string
}

export const ExternalUrlIframe: FC<Props> = ({ title, externalUrl, className = "relative h-[1200px] max-h-full" }) => {
  const hasValidUrl = externalUrl && externalUrl?.length > 8
  const [loading, setLoading] = useState(hasValidUrl)

  useEffect(() => {
    if (hasValidUrl) {
      setLoading(true)
    }
  }, [hasValidUrl])

  return (
    <div className={className}>
      {hasValidUrl && (
        <iframe
          title={title}
          src={externalUrl}
          className="w-full h-full"
          allow="clipboard-write"
          onLoad={() => setLoading(false)}
          sandbox="allow-forms allow-scripts allow-popups allow-same-origin allow-downloads"
        />
      )}
      {loading && (
        <div className="absolute inset-0 w-full h-full flex items-center justify-center bg-white dark:bg-gray-800">
          {loading && <Spinner className="w-8 h-8 text-indigo-600 dark:text-indigo-400" />}
        </div>
      )}
    </div>
  )
}
