import { Dashboard } from "domainModels/dashboard"
import { Group } from "domainModels/group"
import { Widget } from "domainModels/widget"
import { deleteData, fetchData, getData, putData } from "lib/stripe/helpers"
import { useUser } from "lib/supabase/auth"
import { useMutation, useQueryClient, useQuery } from "react-query"
import { getDashboardsKey } from "reactQuery/dashboard"
import { getGroupsKey } from "reactQuery/group"
import { getWidgetsKey } from "reactQuery/widget"

const getPasswordProtectedCacheKeyDashboard = (dashboardId: number) => ["passwordProtectedDashboard", dashboardId]
export const usePasswordProtectedDashboard = (dashboardId: number) => {
  const queryClient = useQueryClient()
  return useQuery<{ dashboard: Dashboard; widgets: Widget[]; groups: Group[] } | null>(
    getPasswordProtectedCacheKeyDashboard(dashboardId),
    async () => {
      const params = new URLSearchParams({
        dashboardId: dashboardId.toString(),
      })

      try {
        const data = await getData({
          url: `/api/dashboard/password?${params.toString()}`,
        })

        return data
      } catch (err) {
        return null
      }
    },
    {
      onSuccess: (data) => {
        if (data?.dashboard) {
          queryClient.setQueryData<Dashboard[]>(getDashboardsKey(data.dashboard.user_id), (oldData = []) => [
            ...oldData,
            data.dashboard,
          ])
        }
        if (data?.groups?.length) {
          queryClient.setQueryData<Group[]>(getGroupsKey(dashboardId), (oldData = []) => [...oldData, ...data.groups])
        }

        if (data?.widgets?.length) {
          queryClient.setQueryData<Widget[]>(getWidgetsKey(dashboardId), (oldData = []) => [
            ...oldData,
            ...data.widgets,
          ])
        }
      },
    }
  )
}

export const useVerifyDashboardPassword = () => {
  const queryClient = useQueryClient()

  return useMutation<void, Error, { dashboardId: number; password?: string }>(async ({ dashboardId, password }) => {
    const res = await fetchData({
      method: "POST",
      url: "/api/dashboard/password",
      body: JSON.stringify({
        dashboardId,
        password,
      }),
    })

    if (res.status === 200) {
      queryClient.invalidateQueries(getPasswordProtectedCacheKeyDashboard(dashboardId))
    }
  })
}

export const useUpsertDashboardPassword = () => {
  const queryClient = useQueryClient()
  const { user, userDetails, session } = useUser()

  return useMutation<void, Error, { dashboardId: number; password?: string }>(async ({ dashboardId, password }) => {
    if (session && userDetails?.stripe_customer_id) {
      await putData({
        url: "/api/dashboard/password",
        token: session?.access_token,
        data: {
          dashboardId,
          password,
          stripeCustomerId: userDetails.stripe_customer_id,
        },
      })

      queryClient.setQueryData<Dashboard[]>(getDashboardsKey(user?.id), (oldData = []) =>
        oldData.map((o) => (o.id === dashboardId ? { ...o, is_password_protected: true } : o))
      )
    }
  })
}

export const useDeleteDashboardPassword = () => {
  const queryClient = useQueryClient()
  const { user, userDetails, session } = useUser()

  return useMutation<void, Error, { dashboardId: number }>(async ({ dashboardId }) => {
    if (session && userDetails?.stripe_customer_id) {
      await deleteData({
        url: "/api/dashboard/password",
        token: session?.access_token,
        data: { dashboardId, stripeCustomerId: userDetails.stripe_customer_id },
      })

      queryClient.setQueryData<Dashboard[]>(getDashboardsKey(user?.id), (oldData = []) =>
        oldData.map((o) => (o.id === dashboardId ? { ...o, is_password_protected: false } : o))
      )
    }
  })
}
