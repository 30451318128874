import { tryBase64Decode, tryBase64Encode } from "components/dashboard/widgets/base64Converter/utils"

const NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF
const NEXT_PUBLIC_VERCEL_ENV = process.env.NEXT_PUBLIC_VERCEL_ENV
const NEXT_PUBLIC_VERCEL_URL = process.env.NEXT_PUBLIC_VERCEL_URL

export enum Environments {
  DEV,
  STAGING,
  PRODUCTION,
}

export const CURRENT_ENVIRONMENT = !NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF
  ? Environments.DEV
  : NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF === "staging"
  ? Environments.STAGING
  : Environments.PRODUCTION

export const BASE_URL =
  NEXT_PUBLIC_VERCEL_ENV === "production"
    ? "https://www.fetoolkit.io"
    : !NEXT_PUBLIC_VERCEL_ENV
    ? "http://localhost:3000"
    : NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF === "staging"
    ? "https://staging.fetoolkit.io"
    : `https://${NEXT_PUBLIC_VERCEL_URL}`

export const stringToBytes = (t: string) => new TextEncoder().encode(t).length

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return "0 Bytes"

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
}

export const inexhaustive = (a: never): never => a

export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>

export const isInstalled = () => {
  if (typeof window === "undefined") {
    return false
  }

  // For iOS
  if ((window.navigator as any).standalone) return true

  // For Android
  if (window.matchMedia("(display-mode: standalone)").matches) return true

  // If neither is true, it's not installed
  return false
}

export const getPriceDisplay = (price: number, currency: string) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
    minimumFractionDigits: 0,
  }).format(price)

export const getDashboardSlug = (id: number, userId: string) => {
  const encodedId = tryBase64Encode(userId.substring(0, 3) + "-" + id.toString())
  return makeUrlFriendly(encodedId)
}

export const getDashboardIdFromSlug = (slug: string) => {
  const decodedSlug = tryBase64Decode(reverseUrlFriendly(slug))
  const encodedId = decodedSlug.substring(decodedSlug.lastIndexOf("-") + 1)
  return +encodedId
}

const makeUrlFriendly = (input: string) => input.replace(/\+/g, "-").replace(/\//g, "_").replace(/\=+$/, "")
const reverseUrlFriendly = (input: string) => {
  if (input.length % 4 != 0) {
    input += "===".slice(0, 4 - (input.length % 4))
  }
  return input.replace(/-/g, "+").replace(/_/g, "/")
}

export const toBinary = (string: string) => {
  const codeUnits = new Uint16Array(string.length)
  for (let i = 0; i < codeUnits.length; i++) {
    codeUnits[i] = string.charCodeAt(i)
  }
  const text = String.fromCharCode(...new Uint8Array(codeUnits.buffer))
  return typeof window === "undefined" ? Buffer.from(text).toString("base64") : window.btoa(text)
}

export interface OgImage {
  url: string
  title: string
  subTitle?: string
  avatar?: string
}

const imageServiceUrl =
  process.env.NODE_ENV === "production" ? "https://frontendtoolkit-og-image.vercel.app/" : "http://localhost:3001/"

export const getScreenshot = (slug: string, theme: string, cacheBust?: string | null) => {
  const url = new URL(`${imageServiceUrl}screenshot.png`)
  url.searchParams.append("screenshotUrl", `${BASE_URL}/dashboard/${slug}`)
  url.searchParams.append("theme", theme)
  if (cacheBust) {
    url.searchParams.append("cacheBust", cacheBust)
  }
  return url.toString()
}

/**
 * Use case:
 * openapi-typescript generates table column definitions, where "NULL" is treated
 * as "undefined" (optional). However, supabase-js client, expects "NULL" values to actually
 * be "null" on the JavaScript side, otherwise operations like update will fail when updating
 * with "undefined".
 */

export type ReplaceUndefinedWithNull<T> = {
  [key in keyof Required<T>]: undefined extends T[key] ? Exclude<T[key], undefined> | null : T[key]
}

// Pixel GIF code adapted from https://stackoverflow.com/a/33919020/266535
const keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/="

const triplet = (e1: number, e2: number, e3: number) =>
  keyStr.charAt(e1 >> 2) +
  keyStr.charAt(((e1 & 3) << 4) | (e2 >> 4)) +
  keyStr.charAt(((e2 & 15) << 2) | (e3 >> 6)) +
  keyStr.charAt(e3 & 63)

export const rgbDataURL = (r: number, g: number, b: number) =>
  `data:image/gif;base64,R0lGODlhAQABAPAA${triplet(0, r, g) + triplet(b, 255, 255)}/yH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==`
