import { Layout } from "domainModels/layout"
import { Group } from "domainModels/group"

interface WidgetConstructor {
  id?: string | number | null
  user_id?: string | null
  is_private: boolean
  dashboard_id?: number | null
  created_at?: string | null
  information: WidgetInformation
  updated_at?: string | null
  group?: Group | null
  layout?: Layout | null
}

export class Widget {
  id: number | string | null
  user_id: Readonly<string | null>
  is_private: boolean
  dashboard_id: number | null
  created_at: string | null
  information: WidgetInformation
  updated_at: string | null
  group: Group | null
  layout: Layout | null

  constructor({
    id,
    user_id,
    is_private,
    dashboard_id,
    created_at,
    information,
    updated_at,
    group,
    layout,
  }: WidgetConstructor) {
    Widget.validateGroupAndLayout(group, layout)
    Widget.validateDashboardAndUserIdsConsistency(dashboard_id, user_id)
    Widget.validateUserIdAndIsPrivate(user_id, is_private)

    this.id = id ?? null
    this.user_id = user_id ?? null
    this.is_private = is_private
    this.dashboard_id = dashboard_id ?? null
    this.created_at = created_at ?? null
    this.information = information
    this.updated_at = updated_at ?? null
    this.group = group ?? null
    this.layout = layout ?? null
  }

  private static validateGroupAndLayout = (group: WidgetConstructor["group"], layout: WidgetConstructor["layout"]) => {
    if (group && layout) {
      throw new Error("Widget can either have group or layout, not both.")
    }
  }

  private static validateDashboardAndUserIdsConsistency = (
    dashboardId: WidgetConstructor["dashboard_id"],
    userId: WidgetConstructor["user_id"]
  ) => {
    if (!dashboardId && userId) {
      throw new Error("Widget without a dashboard id must not have a user_id either.")
    }
    if (dashboardId && !userId) {
      throw new Error("Widget belonging to a dashboard must have a user_id.")
    }
  }

  private static validateUserIdAndIsPrivate = (
    userId: WidgetConstructor["user_id"],
    isPrivate: WidgetConstructor["is_private"]
  ) => {
    if (!userId && isPrivate === true) {
      throw new Error("Widget without a user_id cannot be private.")
    }
  }
}

interface WidgetInformationConstructor {
  id?: string | number | null
  user_id?: string | null
  is_private: boolean
  name: string
  description?: string | null
  markdown?: string | null
  keywords?: any
  slug?: string | null
  external_url?: string | null
}

export class WidgetInformation {
  id: number | string | null
  user_id: Readonly<string | null>
  is_private: boolean
  name: string
  description: string | null
  markdown: string | null
  keywords: string[] | null
  slug: string | null
  external_url: string | null

  constructor({
    id,
    user_id,
    is_private,
    name,
    description,
    markdown,
    keywords,
    slug,
    external_url,
  }: WidgetInformationConstructor) {
    WidgetInformation.validateUserIdAndIsPrivate(user_id, is_private)

    this.id = id ?? null
    this.user_id = user_id ?? null
    this.is_private = is_private
    this.name = name
    this.description = description ?? null
    this.markdown = markdown ?? null
    this.keywords = keywords ?? null
    this.slug = slug ?? null
    this.external_url = external_url ?? null
  }

  private static validateUserIdAndIsPrivate = (
    userId: WidgetInformationConstructor["user_id"],
    isPrivate: WidgetInformationConstructor["is_private"]
  ) => {
    if (!userId && isPrivate === true) {
      throw new Error("Widget information without a user_id cannot be private.")
    }
  }
}
