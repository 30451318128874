import React, { FC, Fragment, ReactNode, useState } from "react"
import { BoltIcon, CodeBracketSquareIcon, PencilSquareIcon } from "@heroicons/react/24/outline"
import { Menu } from "@headlessui/react"
import { Button } from "../../../core/button"
import { useCurrentDashboard } from "reactQuery/dashboard"
import { useInsertWidget } from "reactQuery/widget"
import { Layout } from "domainModels/layout"
import { Widget, WidgetInformation } from "domainModels/widget"
import { useFeature } from "lib/features/useFeature"
import { FeatureName, WIDGETS_FREE_MAX_AMOUNT } from "lib/features/features"
import { useUser } from "lib/supabase/auth"
import { DEFAULT_CUSTON_WIDGET_LAYOUT } from "components/dashboard/grid/config"
import { Modal } from "components/core/modal/modal"
import { Title } from "components/core/modal/title"
import { ExternalUrlIframe } from "components/dashboard/widgets/customWidgets/externalUrlIframe"
import { InputLabel } from "components/core/inputLabel"
import { Link } from "components/core/link"
import { ANONYMOUS_USER_ID } from "components/dashboard/anonymousConfig"
import { WidgetMenuContent, WidgetMenuContentItem } from "components/dashboard/widgets/shared/widgetMenuContent"
import { useUiContext } from "components/providers/uiProvider"
import { Formik } from "formik"

interface Props {
  trigger: ReactNode
  layout?: Layout | null
  position?: "top" | "bottom"
}

export const WidgetAddMenu: FC<Props> = ({ layout, trigger, position }) => {
  const { user } = useUser()
  const { update } = useUiContext()
  const [showEmbedWebsiteModal, setShowEmbedWebsiteModal] = useState(false)
  const { data: dashboard } = useCurrentDashboard()
  const { mutateAsync: insertWidget } = useInsertWidget()
  const { exhausted: widgetsExhausted } = useFeature(FeatureName.WIDGETS_AMOUNT)

  const addWidgetToDashboard = async (widget: Widget) => {
    if (dashboard?.id) {
      const offset = position === "top" ? 1 : position === "bottom" ? -1 : 0
      const newLayout = layout
        ? {
            xs: { ...widget.layout?.xs, x: layout.xs.x, y: layout.xs.y + offset },
            md: { ...widget.layout?.md, x: layout.md.x, y: layout.md.y + offset },
            lg: { ...widget.layout?.lg, x: layout.lg.x, y: layout.lg.y + offset },
            xl: { ...widget.layout?.xl, x: layout.xl.x, y: layout.xl.y + offset },
            xxl: { ...widget.layout?.xxl, x: layout.xxl.x, y: layout.xxl.y + offset },
          }
        : DEFAULT_CUSTON_WIDGET_LAYOUT

      await insertWidget({
        dashboardId: dashboard.id,
        widget: new Widget({
          dashboard_id: dashboard.id,
          layout: new Layout({
            is_private: dashboard.is_private,
            user_id: user?.id ?? ANONYMOUS_USER_ID,
            ...newLayout,
          }),
          information: widget.information,
          is_private: dashboard.is_private,
          user_id: user?.id ?? ANONYMOUS_USER_ID,
        }),
      })
    }
  }

  const menuItems: ReactNode[] = [
    widgetsExhausted ? (
      <div className="text-gray-700 dark:text-gray-300 px-4 py-2">
        {user ? (
          <>
            You can&apos;t add more widgets with your current plan. <Link to="/upgrade">Upgrade now</Link>
          </>
        ) : (
          <>
            You can only have {WIDGETS_FREE_MAX_AMOUNT} widgets on a dashboard. To add more widgets you need to sign up
            for a pro plan. <Link to="/auth/registration">Sign up</Link>
          </>
        )}
      </div>
    ) : (
      <>
        <WidgetMenuContentItem onClick={() => setShowEmbedWebsiteModal(true)}>
          <CodeBracketSquareIcon className="h-4 w-4 mr-2 text-gray-400 dark:text-gray-500" />
          Embed website
        </WidgetMenuContentItem>
        <AddMarkdown onAdd={addWidgetToDashboard} />
        <WidgetMenuContentItem
          onClick={() => {
            if (dashboard) {
              update({
                showSuggestions: true,
                suggestionInfo: {
                  layout: layout ?? DEFAULT_CUSTON_WIDGET_LAYOUT,
                  position,
                },
              })
            }
          }}
        >
          <BoltIcon className="h-4 w-4 mr-2 text-gray-400 dark:text-gray-500" />
          Show suggestions
        </WidgetMenuContentItem>
      </>
    ),
  ]

  return (
    <>
      <Menu as={Fragment}>
        {({ open }) => <WidgetMenuContent open={open} menuItems={menuItems} position="right" trigger={trigger} />}
      </Menu>
      <EmbedWebsiteModal
        showEmbedWebsiteModal={showEmbedWebsiteModal}
        setShowEmbedWebsiteModal={setShowEmbedWebsiteModal}
        addWidgetToDashboard={addWidgetToDashboard}
      />
    </>
  )
}

const EmbedWebsiteModal: FC<{
  showEmbedWebsiteModal: boolean
  setShowEmbedWebsiteModal: (val: boolean) => void
  addWidgetToDashboard: (widget: Widget) => void
}> = ({ showEmbedWebsiteModal, setShowEmbedWebsiteModal, addWidgetToDashboard }) => {
  const { data: dashboard } = useCurrentDashboard()
  const { user } = useUser()

  return (
    <Modal show={showEmbedWebsiteModal} onClose={() => setShowEmbedWebsiteModal(false)}>
      <Title title="Embed website" />

      <Formik
        initialValues={{ name: "", externalUrl: "" }}
        validate={async (values) => {
          const errors: {
            [key: string]: string
          } = {}

          if (!values.name) {
            errors.name = "Please provide a widget name"
          }

          if (!values.externalUrl || !values.externalUrl.includes(".")) {
            errors.externalUrl = "Please provide a valid url"
          }

          return errors
        }}
        onSubmit={async (values, { resetForm }) => {
          if (dashboard) {
            addWidgetToDashboard(
              new Widget({
                dashboard_id: dashboard.id,
                user_id: user?.id ?? ANONYMOUS_USER_ID,
                is_private: dashboard.is_private,
                information: new WidgetInformation({
                  is_private: dashboard.is_private,
                  user_id: user?.id ?? ANONYMOUS_USER_ID,
                  name: values.name,
                  external_url: `https://${values.externalUrl}`,
                }),
                layout: new Layout({
                  user_id: user?.id ?? ANONYMOUS_USER_ID,
                  is_private: dashboard.is_private,
                  ...DEFAULT_CUSTON_WIDGET_LAYOUT,
                }),
              })
            )

            resetForm()
            setShowEmbedWebsiteModal(false)
          }
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <div className="flex flex-col lg:flex-row space-y-8 lg:space-y-0 lg:space-x-8">
              <div className="flex flex-col space-y-4 lg:w-64">
                <InputLabel
                  id="name"
                  label="Name"
                  type="text"
                  onBlur={handleBlur}
                  value={values.name}
                  onChange={handleChange}
                  errors={errors.name && touched.name ? errors.name : null}
                  placeholder="Your widget name"
                />

                <InputLabel
                  type="text"
                  id="externalUrl"
                  label="URL"
                  onBlur={handleBlur}
                  value={values.externalUrl}
                  onChange={handleChange}
                  errors={errors.externalUrl && touched.externalUrl ? errors.externalUrl : null}
                  placeholder="www.example.com"
                  inputClassName="pl-[58px]"
                  inputIconBefore={<div className="text-sm text-gray-500">https://</div>}
                />
              </div>
              <div className="flex-1 rounded-lg overflow-hidden bg-gray-100 dark:bg-gray-900/20">
                <ExternalUrlIframe
                  title={values.name}
                  externalUrl={`https://${values.externalUrl}`}
                  className="relative h-96 max-h-full w-full"
                />
              </div>
            </div>

            <div className="mt-5 md:flex md:flex-row-reverse">
              <Button type="submit" disabled={isSubmitting} variant="primary" className="w-full md:w-auto md:ml-3">
                Add widget
              </Button>
              <Button onClick={() => setShowEmbedWebsiteModal(false)} className="w-full md:w-auto mt-4 md:mt-0">
                Cancel
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  )
}

const DEFAULT_MARKDOWN = `Click here to edit this content. This custom widget lets you use [GitHub Flavored Markdown](https://github.github.com/gfm/#what-is-github-flavored-markdown-).`

const AddMarkdown: FC<{ onAdd: (widget: Widget) => void }> = ({ onAdd }) => {
  const { data: dashboard } = useCurrentDashboard()
  const { user } = useUser()

  return (
    <WidgetMenuContentItem
      onClick={() => {
        if (dashboard) {
          onAdd(
            new Widget({
              dashboard_id: dashboard.id,
              user_id: user?.id ?? ANONYMOUS_USER_ID,
              is_private: dashboard.is_private,
              information: new WidgetInformation({
                is_private: dashboard.is_private,
                user_id: user?.id ?? ANONYMOUS_USER_ID,
                name: "Edit me",
                markdown: DEFAULT_MARKDOWN,
              }),
              layout: new Layout({
                user_id: user?.id ?? ANONYMOUS_USER_ID,
                is_private: dashboard.is_private,
                ...DEFAULT_CUSTON_WIDGET_LAYOUT,
              }),
            })
          )
        }
      }}
    >
      <PencilSquareIcon className="h-4 w-4 mr-2 text-gray-400 dark:text-gray-500" />
      Add markdown
    </WidgetMenuContentItem>
  )
}
