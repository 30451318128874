import { FC } from "react"
import classNames from "classnames"
import { CheckCircleIcon, ExclamationCircleIcon, ExclamationTriangleIcon } from "@heroicons/react/24/outline"

interface AlertProps {
  type: "info" | "error" | "success"
  className?: string
}

const getIcon = (type: string) => {
  switch (type) {
    case "info":
      return <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400 dark:text-orange-300" />
    case "error":
      return <ExclamationCircleIcon className="h-5 w-5 text-red-400" />
    case "success":
      return <CheckCircleIcon className="h-5 w-5 text-green-400" />
    default:
      return null
  }
}

export const Alert: FC<AlertProps> = ({ type, children, className }) => {
  return (
    <div
      className={classNames(
        "p-4 rounded-md",
        {
          "bg-red-50 text-red-700 dark:bg-[#1f1315] dark:text-red-200 dark:border dark:border-[#822025]/90 ":
            type === "error",
          "bg-green-50 text-green-800 dark:bg-[#0c1f17] dark:text-green-200 dark:border dark:border-[#1a543a]":
            type === "success",
          "bg-yellow-50 text-yellow-700 dark:text-orange-200 dark:bg-[#1f1300] dark:border dark:border-[#693f05]":
            type === "info",
        },
        className
      )}
    >
      <div className="flex">
        <div className="flex-shrink-0">{getIcon(type)}</div>
        <div className={classNames("ml-3 text-sm leading-5")}>{children}</div>
      </div>
    </div>
  )
}
