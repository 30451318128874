import { MagnifyingGlassIcon } from "@heroicons/react/24/outline"
import { InputLabel } from "components/core/inputLabel"
import { useUiContext } from "components/providers/uiProvider"
import { FC } from "react"

export const Search: FC = () => {
  const { value, update } = useUiContext()
  const { searchQuery } = value

  return (
    <InputLabel
      id="search"
      type="search"
      className="ml-1 group !shadow-none"
      inputClassName="!bg-transparent !border-transparent text-indigo-100 placeholder-indigo-300 focus-visible:placeholder-indigo-100 py-1.5"
      inputIconBefore={
        <MagnifyingGlassIcon className="w-5 h-5 text-indigo-300 group-focus-within:text-indigo-100 transition-colors duration-150" />
      }
      autoComplete="off"
      value={searchQuery}
      onChange={(ev) => {
        const searchQuery = ev.target.value.toLowerCase()
        return update({ searchQuery, showSuggestions: searchQuery.length > 0 })
      }}
      placeholder="Search..."
    />
  )
}
