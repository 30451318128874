import Tippy, { TippyProps } from "@tippyjs/react"
import { FC, ReactNode } from "react"

interface Props extends TippyProps {
  content: ReactNode
}

export const Tooltip: FC<Props> = ({ content, children, ...tippyProps }) => (
  <Tippy
    arrow
    delay={250}
    animation="shift-away"
    content={<div className="text-gray-700 dark:text-white">{content}</div>}
    className="transition-all rounded-md text-sm py-2 px-2 shadow-md bg-white text-white dark:bg-gray-900 dark:text-gray-900"
    {...tippyProps}
  >
    <span className="cursor-help">{children}</span>
  </Tippy>
)
