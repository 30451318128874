import { supabase } from "lib/supabase/client"
import { Widget, WidgetInformation } from "domainModels/widget"
import { Layout } from "domainModels/layout"
import { insertLayout, updateLayout } from "repository/layout"
import { insertGroup, updateGroup } from "repository/group"
import { Group } from "domainModels/group"
import { Optional } from "lib/utils"
import { SupabaseClient } from "@supabase/supabase-js"

export const selectWidgets = async (dashboardId: number, supabaseClient?: SupabaseClient) => {
  const client = supabaseClient ?? supabase
  const { data } = await client
    .from("widget")
    .select(
      `
                id,
                dashboard_id,
                user_id,
                is_private,
                created_at,
                updated_at,
                group: widget_group_id_fkey (
                    id,
                    user_id,
                    is_private,
                    layout: group_layout_id_fkey (
                        id,
                        user_id,
                        is_private,
                        xs,
                        md,
                        lg,
                        xl,
                        xxl            
                    ),
                    name
                ),
                layout: widget_layout_id_fkey (
                    user_id,
                    is_private,
                    id,
                    xs,
                    md,
                    lg,
                    xl,
                    xxl
                ),
                widget_information: widget_widget_information_id_fkey (
                    id,
                    user_id,
                    is_private,
                    name,
                    description,
                    markdown,
                    keywords,
                    slug,
                    external_url
                )
            `
    )
    .eq("dashboard_id", dashboardId)
    .throwOnError()

  if (!data) {
    return null
  }

  return data.map((record) => {
    const { widget_information, group, layout } = record
    return new Widget({
      ...record,
      user_id: record.user_id,
      // @ts-ignore
      information: new WidgetInformation(widget_information),
      group: group
        ? // @ts-ignore
          new Group({
            ...group,
            layout: new Layout({
              // @ts-ignore
              ...group.layout,
              // @ts-ignore
              user_id: group.layout.user_id,
            }),
          })
        : null,
      layout: layout
        ? // @ts-ignore
          new Layout({
            ...layout,
            // @ts-ignore
            user_id: layout.user_id,
          })
        : null,
    })
  })
}

export const selectPublicWidgetsCount = async (dashboardId: number) => {
  const { count } = await supabase
    .from("widget")
    .select("id", { count: "exact" })
    .eq("dashboard_id", dashboardId)
    .eq("is_private", false)
    .throwOnError()

  return count
}

// Data-modifying CTE: https://www.postgresql.org/docs/10/queries-with.html#QUERIES-WITH-MODIFYING
// Seem to be unsupported in postgREST:
// https://github.com/PostgREST/postgrest/issues/818#issuecomment-409981816
// https://github.com/supabase/postgrest-js/issues/219
// The best we can do is to fire API requests in parallel.
export const updateWidget = async (widget: Optional<Widget, "information">) => {
  if (widget.id === null) {
    throw new Error("Widget instance has no id. Cannot perform update.")
  }
  const { information, layout, group } = widget
  let newInformation = information
  if (information) {
    if (information.id) {
      updateWidgetInformation(information)
    } else {
      newInformation = await insertWidgetInformation(information)
    }
  }
  await Promise.all([
    updateWidgetRow({ ...widget, information: newInformation }),
    group && updateGroup(group),
    layout && updateLayout(layout),
  ])
}

export const updateWidgetRow = async (widget: Optional<Widget, "information">) => {
  if (widget.id === null) {
    throw new Error("Widget instance has no id. Cannot perform update.")
  }
  if (
    typeof widget.id === "string" ||
    typeof widget.information?.id === "string" ||
    typeof widget.group?.id === "string" ||
    typeof widget.layout?.id === "string"
  ) {
    throw new Error("Insertion with uuid not allowed")
  }
  const { count } = await supabase
    .from("widget")
    .update(
      {
        group_id: widget.group?.id ?? null,
        layout_id: widget.layout?.id ?? null,
        dashboard_id: widget.dashboard_id,
        widget_information_id: widget.information !== undefined ? widget.information?.id ?? null : undefined,
        updated_at: widget.updated_at,
      },
      { count: "exact" }
    )
    .eq("id", widget.id)
    .throwOnError()
  if (count === 0) {
    throw new Error("No widget rows were updated.")
  }
}

export const updateWidgetInformation = async (information: Widget["information"]) => {
  if (information.id === null) {
    throw new Error("WidgetInformation instance has no id. Cannot perform update.")
  }
  if (typeof information.id === "string") {
    throw new Error("Insertion with uuid not allowed")
  }
  const { count } = await supabase
    .from("widget_information")
    .update(
      {
        name: information.name,
        description: information.description,
        markdown: information.markdown,
        keywords: information.keywords,
        slug: information.slug,
        external_url: information.external_url,
      },
      { count: "exact" }
    )
    .eq("id", information.id)
    .throwOnError()
  if (count === 0) {
    throw new Error("No widget information rows were updated.")
  }
}

export const insertWidgetInformation = async (information: WidgetInformation) => {
  if (information.id !== null) {
    throw new Error("No manual PRIMARY KEY insertion for type SERIAL.")
  }
  const { data, error, status } = await supabase
    .from("widget_information")
    .insert({
      name: information.name,
      description: information.description,
      markdown: information.markdown,
      keywords: information.keywords,
      slug: information.slug,
      external_url: information.external_url,
    })
    .select()
    .single()
  if (error && status !== 406) {
    throw new Error(error.message)
  }
  if (!data) {
    throw new Error("No recond was returned for widget_information insertion.")
  }
  return new WidgetInformation(data)
}

export const insertWidget = async (widget: Widget) => {
  if (widget.id !== null) {
    throw new Error("No manual PRIMARY KEY insertion for type SERIAL.")
  }
  const { information, layout, group } = widget
  const linkToInformation = information.id !== undefined && information.id !== null
  const linkToGroup = group?.id !== undefined && group?.id !== null
  const data = await Promise.all([
    !linkToInformation && insertWidgetInformation(information),
    layout && insertLayout(layout),
    !linkToGroup && group && insertGroup(group),
  ]).then(async (result) => {
    const informationInsertion = result[0]
    const layoutInsertion = result[1]
    const groupInsertion = result[2]

    if (linkToInformation === true && informationInsertion !== false) {
      throw new Error(
        "API inconsistency. Trying to link a widget to information, while also creating new information for it."
      )
    }
    if (linkToGroup === true && groupInsertion !== false) {
      throw new Error("API inconsistency. Trying to link a widget to group, while also creating new group for it.")
    }

    const widgetInformation: WidgetInformation = informationInsertion === false ? information : informationInsertion
    const widgetGroup: Group | null = groupInsertion === false ? group : groupInsertion

    const data = await insertWidgetRow(widget, widgetInformation, layoutInsertion, widgetGroup)
    return data
  })
  return new Widget({
    // @ts-ignore
    ...data,
    information: new WidgetInformation({
      ...information,
      // @ts-ignore
      id: data.widget_information_id,
    }),
    // @ts-ignore
    group: data.group_id !== null && group ? new Group({ ...group, id: data.group_id }) : null,
    layout:
      // @ts-ignore
      data.layout_id !== null && layout
        ? new Layout({
            ...layout,
            // @ts-ignore
            id: data.layout_id,
          })
        : null,
  })
}

export const insertWidgetRow = async (
  widget: Widget,
  widgetInformation: WidgetInformation,
  layoutInsertion: Layout | null,
  widgetGroup: Group | null
) => {
  if (
    typeof widgetGroup?.id === "string" ||
    typeof layoutInsertion?.id === "string" ||
    typeof widgetInformation.id === "string"
  ) {
    throw new Error("Insertion with uuid not allowed")
  }
  const { data, error, status } = await supabase
    .from("widget")
    .insert({
      dashboard_id: widget.dashboard_id,
      updated_at: widget.updated_at,
      group_id: widgetGroup ? widgetGroup.id : null,
      layout_id: layoutInsertion ? layoutInsertion.id : null,
      widget_information_id: widgetInformation.id,
    })
    .select()
    .single()
  if (error && status !== 406) {
    throw new Error(error.message)
  }
  if (!data) {
    throw new Error("No recond was returned for widget insertion.")
  }
  return data
}

export const deleteWidget = async (widget: Widget, isBuiltInInformation: boolean) => {
  if (widget.id === null) {
    throw new Error("Widget instance has no id. Cannot perform delete.")
  }
  if (widget.information.id === null) {
    throw new Error("Widget information instance has no id. Cannot perform delete.")
  }

  if (!widget.layout?.id && !widget.group?.id) {
    throw new Error(
      "Widget domain object is missing both, layout and group ids. Cannot perform deletion. At least one has to be present."
    )
  }

  if (typeof widget.id === "string" || typeof widget.group?.id === "string" || typeof widget.layout?.id === "string") {
    throw new Error("Insertion with uuid not allowed")
  }

  await Promise.all([
    deleteWidgetRow(widget.id as number),
    !isBuiltInInformation && deleteWidgetInformation(widget.information.id as number),
    widget.layout?.id !== undefined && deleteWidgetLayout(widget.layout.id as number),
    widget.group?.id !== undefined && deleteWidgetGroup(widget.group.id as number),
  ])
}

export const deleteWidgetRow = async (widgetId: number) => {
  const { count } = await supabase.from("widget").delete({ count: "exact" }).eq("id", widgetId).throwOnError()
  if (count === 0) {
    throw new Error("No widget rows were deleted.")
  }
}

export const deleteWidgetInformation = async (widgetInformationId: number) => {
  const { count } = await supabase
    .from("widget_information")
    .delete({ count: "exact" })
    .eq("id", widgetInformationId)
    .throwOnError()
  if (count === 0) {
    throw new Error("No widget information rows were deleted.")
  }
}

const deleteWidgetGroup = async (widgetGroupId: number) => {
  const { count } = await supabase.from("group").delete({ count: "exact" }).eq("id", widgetGroupId).throwOnError()
  if (count === 0) {
    throw new Error("No group rows were deleted.")
  }
}

export const deleteWidgetLayout = async (widgetLayoutId: number) => {
  const { count } = await supabase.from("layout").delete({ count: "exact" }).eq("id", widgetLayoutId).throwOnError()
  if (count === 0) {
    throw new Error("No layout rows were deleted.")
  }
}

export const selectPublicWidgets = async () => {
  const { data } = await supabase
    .from("widget")
    .select(
      `
                id,
                dashboard_id,
                user_id,
                is_private,
                created_at,
                updated_at,
                layout: widget_layout_id_fkey (
                    user_id,
                    is_private,
                    id,
                    xs,
                    md,
                    lg,
                    xl,
                    xxl
                ),
                widget_information: widget_widget_information_id_fkey (
                    id,
                    user_id,
                    is_private,
                    name,
                    description,
                    markdown,
                    keywords,
                    slug,
                    external_url
                )
            `
    )
    .is("dashboard_id", null)
    .throwOnError()

  if (!data) {
    return null
  }
  return data.map((record) => {
    const { widget_information, layout } = record
    return new Widget({
      ...record,
      // @ts-ignore
      information: new WidgetInformation(widget_information),
      group: null,
      // @ts-ignore
      layout: layout ? new Layout({ ...layout }) : null,
    })
  })
}
