import React, { FC } from "react"
import { Logo } from "components/layout/logo"
import { useRouter } from "next/router"
import { useUser } from "lib/supabase/auth"
import { UserMenu } from "./userMenu"
import { Link } from "components/core/link"
import classNames from "classnames"
import { ANONYMOUS_DASHBOARD_ID } from "components/dashboard/anonymousConfig"
import { useSelectDashboards } from "reactQuery/dashboard"
import { MobileNavigation } from "./mobileNavigation"
import { Disclosure } from "@headlessui/react"
import { ChevronRightIcon, Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline"
import { getDashboardSlug } from "lib/utils"

const headerLinkClass =
  "px-4 py-2 shadow-sm text-sm font-medium rounded-full !text-white bg-indigo-700 hover:bg-indigo-600"

export const Header: FC = () => {
  const { user } = useUser()
  const { pathname } = useRouter()
  const showDashboardNavigation = pathname.includes("/dashboard") || pathname.includes("/account")

  return (
    <Disclosure as="nav" className="bg-gray-800">
      {({ open, close }) => (
        <>
          <div className="h-[76px] relative z-40 bg-indigo-800 px-4 sm:px-6 lg:px-12 py-5 flex justify-between items-center">
            <div className="flex-1">
              <Logo />
            </div>

            <div className="hidden md:flex items-center flex-1 justify-center">
              {showDashboardNavigation ? <DefaultDashboardMiddleNavigation /> : <PublicPageMiddleNavigation />}
            </div>

            <div className="flex items-center flex-1 justify-end">
              <div className="relative lg:min-w-[250px] text-right">
                {user ? (
                  <UserMenu user={user} />
                ) : (
                  <Link to="/auth/login" className={headerLinkClass}>
                    Sign in
                    <ChevronRightIcon className="hidden md:inline-block ml-1 h-4 w-4 mt-0.5 -mr-1" />
                  </Link>
                )}
              </div>

              <Disclosure.Button className="inline-flex md:hidden ml-2 items-center justify-center px-4 py-2 sm:px-3 sm:py-1.5 text-white focus-visible:ring-indigo-500 focus-visible:ring-2 rounded-full bg-indigo-800 hover:!bg-indigo-700">
                <span className="sr-only">Open main menu</span>
                {open ? (
                  <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                ) : (
                  <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                )}
              </Disclosure.Button>
            </div>
          </div>

          <Disclosure.Panel className="md:hidden">
            <MobileNavigation
              close={close}
              isDashboardOwner={false}
              showDashboardNavigation={showDashboardNavigation}
            />
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}

const PublicPageMiddleNavigation: FC = () => {
  const { asPath } = useRouter()
  const { data: dashboards } = useSelectDashboards()
  const primaryDashboard = dashboards?.find((d) => d.is_primary)
  const dashboardId = primaryDashboard?.id
  const isAnnonymous = dashboardId === ANONYMOUS_DASHBOARD_ID
  const href =
    isAnnonymous || !dashboardId ? `/dashboard` : `/dashboard/${getDashboardSlug(dashboardId, primaryDashboard.name)}`

  return (
    <>
      <Link
        to={href}
        className={classNames(
          "px-3 py-1 mx-2 transition !text-gray-200 hover:!text-white hover:bg-indigo-700 font-medium",
          {
            "!bg-indigo-700": asPath === "/dashboard",
          }
        )}
      >
        Dashboard
      </Link>
      <Link
        to="/explore"
        className={classNames(
          "px-3 py-1 mx-2 transition !text-gray-200 hover:!text-white hover:bg-indigo-700 font-medium",
          {
            "!bg-indigo-700": asPath === "/explore",
          }
        )}
      >
        Explore
      </Link>
    </>
  )
}

const DefaultDashboardMiddleNavigation: FC = () => {
  const { data: dashboards } = useSelectDashboards()
  const { user } = useUser()
  const primaryDashboard = dashboards?.find((d) => d.is_primary)
  const dashboardId = primaryDashboard?.id
  const isAnnonymous = dashboardId === ANONYMOUS_DASHBOARD_ID
  const href =
    isAnnonymous || !dashboardId || !user ? `/dashboard` : `/dashboard/${getDashboardSlug(dashboardId, user.id)}`

  return (
    <Link to={href} className={classNames(headerLinkClass, "mr-2 inline-flex items-center")}>
      Go to your Dashboard
    </Link>
  )
}
