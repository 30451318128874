import { Dialog, Transition } from "@headlessui/react"
import { XMarkIcon } from "@heroicons/react/24/outline"
import classNames from "classnames"
import { Button } from "components/core/button"
import { Widget } from "domainModels/widget"
import React, { FC, Fragment, useRef } from "react"

interface Props {
  show: boolean
  closeDisabled?: boolean
  className?: string
  onClose?: () => void
  afterLeave?: () => void
}

export const Modal: FC<Props> = ({
  children,
  show,
  className = "max-w-7xl",
  closeDisabled = false,
  onClose,
  afterLeave,
}) => {
  const completeButtonRef = useRef<HTMLButtonElement | null>(null)

  return (
    <Transition.Root show={show} as={Fragment} afterLeave={afterLeave}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto"
        onClose={() => !closeDisabled && onClose?.()}
        initialFocus={completeButtonRef}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-4 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 dark:bg-black dark:bg-opacity-75 transition-opacity backdrop-blur-sm" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={classNames(
                "w-full inline-block align-bottom text-left transform transition-all sm:my-8 sm:align-middle sm:p-6",
                className
              )}
            >
              <div className="relative px-6 pt-4 pb-6 rounded-xl shadow-xl bg-white dark:bg-gray-900 bg-opacity-95 dark:bg-opacity-70 before:content-[''] before:absolute before:rounded-[inherit] before:-inset-px before:-z-10 before:dark:bg-gradient-to-br before:dark:from-[#2d3367] before:dark:to-[#412e69]">
                {!closeDisabled && (
                  <div className="absolute z-10 -top-1 right-0 p-3.5">
                    <Button
                      type="button"
                      variant="naked"
                      aria-label="Close"
                      onClick={e => {
                        e.stopPropagation();
                        onClose?.();
                      }}
                      innerRef={completeButtonRef}
                      className="-mr-3"
                    >
                      <XMarkIcon className="h-6 w-6" />
                    </Button>
                  </div>
                )}
                {children}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export const getModalMaxWidth = (widget: Widget | undefined) => {
  const slug = widget?.information.slug
  return slug === "color-converter" ||
    slug === "package-size" ||
    slug === "regex-tester" ||
    slug === "image-converter" ||
    slug === "css-svg-symbols" ||
    slug === "css-triangle" ||
    !!widget?.information.markdown
    ? "md:max-w-7xl"
    : "md:max-w-[90vw]"
}
