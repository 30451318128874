import { getModalMaxWidth, Modal } from "components/core/modal/modal"
import { WidgetConfig } from "components/dashboard/grid/config"
import isSvg from "is-svg"
import React, { FC, useCallback, useEffect, useState } from "react"
import { useSelectPublicWidgets } from "reactQuery/widget"
import tinycolor from "tinycolor2"
import { colorConverterConfig } from "../dashboard/widgets/colorConverter/colorConverterConfig"
import { svgConverterConfig } from "../dashboard/widgets/svgConverter/svgConverterConfig"

export const Clipboard: FC = () => {
  const { data: publicWidgets } = useSelectPublicWidgets()
  const [widgetConfig, setWidgetConfig] = useState<WidgetConfig>()
  const [input, setInput] = useState<tinycolor.Instance | string>()
  const [showModal, setShowModal] = useState(false)
  const widget = publicWidgets?.find((w) => w.information.slug === widgetConfig?.slug)

  const handlePaste = useCallback((ev: any) => {
    const target = ev.target as HTMLElement
    const pastedToWidgetPreview =
      !!target.closest(".react-grid-item") && ["textarea", "input"].includes(target.tagName.toLowerCase())
    const pastedToEditor = target.classList.contains("monaco-mouse-cursor-text")

    if (pastedToWidgetPreview || pastedToEditor) {
      return
    }

    const data = ev.clipboardData.getData("Text")
    const color = tinycolor(data)

    if (color.isValid() && data.length < 25) {
      ev.stopPropagation()
      ev.preventDefault()
      setInput(color)
      setWidgetConfig(colorConverterConfig)
      setShowModal(true)
    } else if (isSvg(data)) {
      ev.stopPropagation()
      ev.preventDefault()
      setInput(data)
      setWidgetConfig(svgConverterConfig)
      setShowModal(true)
    }
  }, [])

  useEffect(() => {
    document.querySelector("body")?.addEventListener("paste", handlePaste)

    return () => {
      document.querySelector("body")?.removeEventListener("paste", handlePaste)
    }
  }, [handlePaste])

  return (
    <Modal
      show={showModal}
      className={getModalMaxWidth(widget)}
      onClose={() => setShowModal(false)}
      afterLeave={() => setWidgetConfig(undefined)}
    >
      {widgetConfig?.renderModal({ input, widget })}
    </Modal>
  )
}
