import React, { FC } from "react"
import { Feedback } from "components/layout/feedback"
import { Link } from "components/core/link"
import { ThemeToggle } from "./header/themeToggle"

export const Footer: FC = () => (
  <footer className="relative border-t border-gray-500/10 bg-white dark:bg-gray-900">
    <div className="relative z-20 px-4 sm:px-6 lg:px-12 py-5 lg:py-3 flex justify-between flex-wrap items-center text-sm">
      <div className="md:-mb-2 mt-4 lg:mt-0 order-1 lg:order-none mx-auto lg:mx-0">
        <Link
          to="https://twitter.com/fetoolkitio"
          target="_blank"
          rel="noreferrer noopener"
          className="text-center w-full md:w-auto rounded-none p-3 md:py-0 md:px-5 md:-mx-4 !text-gray-500 dark:!text-gray-300 hover:!text-gray-700 dark:hover:!text-gray-100"
        >
          <svg className="w-5 h-5 mr-1" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
            <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
          </svg>
          @fetoolkitio
        </Link>
      </div>

      <div className="w-full lg:w-auto flex flex-wrap justify-center items-center">
        <ThemeToggle />

        <div className="w-full md:w-auto md:flex divide-y md:divide-y-0 md:divide-x divide-gray-100 dark:divide-gray-800/40 mr-5">
          <Link
            to="/terms"
            className="text-center w-full md:w-auto rounded-none p-3 md:py-0 md:px-5 !text-gray-500 dark:!text-gray-300 hover:!text-gray-700 dark:hover:!text-gray-100"
          >
            Terms of use
          </Link>
          <Link
            to="/privacy-policy"
            className="text-center w-full md:w-auto rounded-none p-3 md:py-0 md:px-5 !text-gray-500 dark:!text-gray-300 hover:!text-gray-700 dark:hover:!text-gray-100"
          >
            Privacy Policy
          </Link>
        </div>

        <div className="w-full md:w-auto mt-4 md:mt-0 text-center">
          <Feedback />
        </div>
      </div>
    </div>
  </footer>
)
