import classNames from "classnames"
import { ButtonProps, getButtonClassNames } from "components/core/button"
import NextLink from "next/link"
import React, { AnchorHTMLAttributes, FC } from "react"

interface Props extends AnchorHTMLAttributes<HTMLAnchorElement> {
  variant?: "link" | ButtonProps["variant"]
  size?: ButtonProps["size"]
  to: string
  scroll?: boolean
}

export const Link: FC<Props> = ({
  children,
  variant = "link",
  size = "regular",
  target,
  className,
  to,
  scroll,
  ...props
}) => {
  const finalClassName = classNames(
    {
      "rounded-md inline-flex items-center font-medium text-indigo-600 hover:text-indigo-900 dark:text-indigo-400 dark:hover:text-indigo-500 focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:border-indigo-500":
        variant === "link",
      [getButtonClassNames(variant as ButtonProps["variant"], size)]: variant !== "link",
    },
    className
  )

  if (target === "_blank") {
    return (
      <a href={to} className={finalClassName} target={target} {...props}>
        {children}
      </a>
    )
  }

  return (
    <NextLink href={to} scroll={scroll} className={finalClassName} target={target} {...props}>
      {children}
    </NextLink>
  )
}
