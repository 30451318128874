export const fetchData = async ({
  url,
  token,
  body,
  method,
}: {
  url: string
  method: string
  token?: string
  body?: string
}) => {
  const headers = new Headers({ "Content-Type": "application/json" })

  if (token) {
    headers.append("token", token)
  }

  const res = await fetch(url, {
    method,
    headers,
    credentials: "same-origin",
    body,
  })

  if (res.status === 401) {
    throw Error("Unauthorized")
  }

  return res
}

const fetchJSONData = async (props: { url: string; method: string; token?: string; body?: string }) => {
  const res = await fetchData(props)
  return res.json()
}

export const postData = async ({ url, token, data = {} }: { url: string; token?: string; data?: {} }) =>
  fetchJSONData({ url, token, body: JSON.stringify(data), method: "POST" })

export const deleteData = async ({ url, token, data = {} }: { url: string; token?: string; data?: {} }) =>
  fetchJSONData({ url, token, body: JSON.stringify(data), method: "DELETE" })

export const putData = async ({ url, token, data = {} }: { url: string; token?: string; data?: {} }) =>
  fetchJSONData({ url, token, body: JSON.stringify(data), method: "PUT" })

export const getData = async ({ url, token }: { url: string; token?: string; data?: {} }) =>
  fetchJSONData({ url, token, method: "GET" })
