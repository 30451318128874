import { ReactQueryProvider } from "components/providers/reactQueryProvider"
import { UiProvider } from "components/providers/uiProvider"
import { UserContextProvider } from "lib/supabase/auth"
import React, { FC } from "react"

interface Props {
  pageProps: any
}

export const Providers: FC<Props> = ({ children, pageProps }) => (
  <ReactQueryProvider pageProps={pageProps}>
    <UserContextProvider>
      <UiProvider>{children}</UiProvider>
    </UserContextProvider>
  </ReactQueryProvider>
)
