import React, { FC } from "react"
import { Spinner } from "../spinner"

export const Loading: FC = () => (
  <div className="relative h-96">
    <div className="absolute inset-0 flex items-center justify-center pointer-events-none">
      <Spinner className="w-8 h-8 text-indigo-600 dark:text-indigo-400" />
    </div>
  </div>
)
