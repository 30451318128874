import { useMutation, useQuery, useQueryClient } from "react-query"
import { Dashboard } from "domainModels/dashboard"
import {
  deleteDashboard,
  insertDashboard,
  selectDashboards,
  selectPublicDashboard,
  selectPublicDashboards,
  updateDashboard,
} from "repository/dashboard"
import { ANONYMOUS_DASHBOARD_ID, ANONYMOUS_USER_ID } from "components/dashboard/anonymousConfig"
import { useUser } from "lib/supabase/auth"
import { useRouter } from "next/router"
import { Widget } from "domainModels/widget"
import { Group } from "domainModels/group"
import { useInsertWidget } from "reactQuery/widget"
import { useInsertGroup } from "reactQuery/group"
import { getDashboardIdFromSlug } from "lib/utils"
import { postData } from "lib/stripe/helpers"

export const getDashboardsKey = (userId?: string) => (userId ? ["dashboards", userId] : ["dashboards"])

export const useSelectDashboards = () => {
  const { user } = useUser()

  return useQuery<Dashboard[] | null, Error>(getDashboardsKey(user?.id), async () => {
    if (user?.id) {
      return selectDashboards(user.id)
    } else {
      const dashboards = getDashboardsFromLocalStorage()

      if (!dashboards) {
        const newDashboards = [
          new Dashboard({
            id: ANONYMOUS_DASHBOARD_ID,
            user_id: ANONYMOUS_USER_ID,
            name: "My Dashboard",
            owner_name: ANONYMOUS_USER_ID,
            is_primary: true,
            is_private: false,
            is_forked: false,
            is_password_protected: false,
          }),
        ]

        localStorage.setItem(DASHBOARD_LOCAL_STORAGE_KEY, JSON.stringify(newDashboards))

        return newDashboards
      }

      return dashboards
    }
  })
}

export const useUpdateDashboard = () => {
  const queryClient = useQueryClient()
  const { user } = useUser()

  return useMutation<void, Error, Dashboard>(async (dashboard) => {
    queryClient.setQueryData<Dashboard[]>(getDashboardsKey(user?.id), (oldData = []) =>
      oldData.map((o) => (o.id === dashboard.id ? dashboard : o))
    )

    if (user) {
      await updateDashboard({ ...dashboard, updated_at: new Date().toISOString() })
    } else {
      localStorage.setItem(
        DASHBOARD_LOCAL_STORAGE_KEY,
        JSON.stringify(getDashboardsFromLocalStorage()?.map((d) => (d.id === dashboard.id ? dashboard : d)))
      )
    }
  })
}

export const useInsertDashboard = () => {
  const queryClient = useQueryClient()
  const { user } = useUser()

  return useMutation<Dashboard, Error, { dashboard: Dashboard; optimisticUpdate?: boolean }>(
    async ({ dashboard, optimisticUpdate = true }) => {
      if (user) {
        const insertedDashboard = await insertDashboard(dashboard)

        if (optimisticUpdate) {
          queryClient.setQueryData<Dashboard[]>(getDashboardsKey(user?.id), (oldData = []) => [
            ...oldData,
            insertedDashboard,
          ])
        }

        return insertedDashboard
      } else {
        const dashboards = getDashboardsFromLocalStorage()

        if (dashboards) {
          localStorage.setItem(DASHBOARD_LOCAL_STORAGE_KEY, JSON.stringify([...dashboards, dashboard]))
        }

        return dashboard
      }
    }
  )
}

export const useForkDashboard = () => {
  const queryClient = useQueryClient()
  const { user } = useUser()
  const { mutateAsync: insertDashboard } = useInsertDashboard()
  const { mutateAsync: insertWidget } = useInsertWidget()
  const { mutateAsync: insertGroup } = useInsertGroup()

  return useMutation<Dashboard, Error, { dashboard: Dashboard; widgets: Widget[]; groups: Group[] }>(
    async ({ dashboard, widgets, groups }) => {
      if (!user) {
        throw new Error("Not implemented")
      }

      const insertedDashboard = await insertDashboard({
        dashboard,
        optimisticUpdate: false,
      })

      const oldToNewGroup = new Map<Group["id"], Group>()

      if (groups && insertedDashboard.id) {
        await Promise.all(
          groups.map(
            (g) =>
              new Promise<void>(async (resolve) => {
                const insertedGroup = await insertGroup({
                  dashboardId: insertedDashboard.id as number,
                  group: {
                    ...g,
                    id: null,
                    layout: { ...g.layout, id: null },
                  },
                })
                oldToNewGroup.set(g.id, insertedGroup)
                resolve()
              })
          )
        )
      }

      if (widgets && insertedDashboard) {
        await Promise.all(
          widgets.map((w) =>
            insertWidget({
              dashboardId: insertedDashboard.id as number,
              widget: {
                ...w,
                id: null,
                dashboard_id: insertedDashboard.id,
                layout: w.layout ? { ...w.layout, id: null } : null,
                group: w.group ? oldToNewGroup.get(w.group.id) ?? null : null,
                information: w.information,
              },
            })
          )
        )
      }

      queryClient.setQueryData<Dashboard[]>(getDashboardsKey(user?.id), (oldData = []) => [
        ...oldData,
        insertedDashboard,
      ])

      return insertedDashboard
    }
  )
}

export const useDeleteDashboard = () => {
  const queryClient = useQueryClient()
  const { user } = useUser()

  return useMutation<void, Error, Dashboard>(async (dashboard) => {
    if (user) {
      queryClient.setQueryData<Dashboard[]>(getDashboardsKey(user?.id), (oldData = []) =>
        oldData.filter((o) => o.id !== dashboard.id)
      )
      return deleteDashboard(dashboard)
    } else {
      const dashboards = getDashboardsFromLocalStorage()

      if (dashboards) {
        localStorage.setItem(
          DASHBOARD_LOCAL_STORAGE_KEY,
          JSON.stringify(dashboards.filter((d) => d.id !== dashboard.id))
        )
      }
    }
  })
}

export const useCurrentDashboard = () => {
  const { data: userDashboards, isLoading } = useSelectDashboards()
  const { query } = useRouter()
  const slug = query.slug?.toString()
  const id = slug ? getDashboardIdFromSlug(slug) : ANONYMOUS_DASHBOARD_ID

  return { isLoading, data: userDashboards?.find((d) => d.id === id) }
}

export const useIsDashboardOwner = () => {
  const { user } = useUser()
  const { pathname } = useRouter()
  const { data: dashboard } = useCurrentDashboard()
  const isDashboardOwner = user && dashboard?.user_id === user?.id

  if (!user && pathname === "/dashboard") {
    return true
  }

  return !!isDashboardOwner
}

const getPublicDashboardCacheKey = (id?: number) => ["dashboard", "public", id]
export const usePublicDashboard = (id?: number) =>
  useQuery<Dashboard | null, Error>(getPublicDashboardCacheKey(id), () => selectPublicDashboard(id!), {
    enabled: !!id,
  })

const getPublicDashboardsCacheKey = () => ["dashboards", "public"]
export const usePublicDashboards = () =>
  useQuery<Dashboard[] | null, Error>(getPublicDashboardsCacheKey(), () => selectPublicDashboards())

const DASHBOARD_LOCAL_STORAGE_KEY = "dashboard"
export const getDashboardsFromLocalStorage = () => {
  let dashboards: Dashboard[] | null = null

  try {
    dashboards = JSON.parse(localStorage.getItem(DASHBOARD_LOCAL_STORAGE_KEY)!)
  } finally {
    return dashboards
  }
}

export const useUpdateDashboardIsPrivate = () => {
  const queryClient = useQueryClient()
  const { user, userDetails, session } = useUser()

  return useMutation<void, Error, { dashboardId: number; isPrivate: boolean }>(async ({ dashboardId, isPrivate }) => {
    if (session && userDetails?.stripe_customer_id) {
      queryClient.setQueryData<Dashboard[]>(getDashboardsKey(user?.id), (oldData = []) =>
        oldData.map((o) =>
          o.id === dashboardId
            ? { ...o, is_private: isPrivate, is_password_protected: !isPrivate ? false : o.is_password_protected }
            : o
        )
      )

      await postData({
        url: "/api/dashboard",
        token: session?.access_token,
        data: {
          dashboardId,
          isPrivate,
          stripeCustomerId: userDetails.stripe_customer_id,
        },
      })
    }
  })
}
