import { User } from "@supabase/supabase-js"
import { Dashboard } from "domainModels/dashboard"
import { Widget } from "domainModels/widget"
import { PRO_USERS } from "lib/proUsers.config"
import { Stripe } from "stripe"

export enum FeatureName {
  DASHBOARD_AMOUNT,
  DASHBOARD_PRIVATE,
  AI_WIDGETS,
  WIDGETS_AMOUNT,
}

const DASHBOARD_FREE_MAX_AMOUNT = 2
const DASHBOARD_PRO_MAX_AMOUNT = 10
export const WIDGETS_FREE_MAX_AMOUNT = 10
const WIDGETS_PRO_MAX_AMOUNT = 100
export const WIDGETS_ON_DEFAULT_DASHBOARD = [
  "color-converter",
  "svg-converter",
  "json-converter",
  "css-triangle",
  "css-svg-symbols",
  "js-converter",
  "image-converter",
]

interface Data {
  user?: User | null
  subscription?: Stripe.Subscription | null
  dashboards?: Dashboard[] | null
  widgets?: Widget[] | null
}

interface Feature {
  id: FeatureName
  isEnabled: (data: Data) => boolean
  isExhausted: (data: Data) => boolean
}

export const FEATURES: Feature[] = [
  {
    id: FeatureName.DASHBOARD_AMOUNT,
    isEnabled: ({ user }) => !!user,
    isExhausted: ({ user, subscription, dashboards }) => {
      if (!user) {
        return true
      }

      if (PRO_USERS.includes(user.id)) {
        return false
      }

      if (!dashboards) {
        return false
      }

      if (subscription) {
        return dashboards?.length >= DASHBOARD_PRO_MAX_AMOUNT
      } else {
        return dashboards?.length >= DASHBOARD_FREE_MAX_AMOUNT
      }
    },
  },
  {
    id: FeatureName.DASHBOARD_PRIVATE,
    isEnabled: ({ user, subscription }) => !!user && (!!subscription || PRO_USERS.includes(user.id)),
    isExhausted: () => false,
  },
  {
    id: FeatureName.AI_WIDGETS,
    isEnabled: ({ user, subscription }) => !!user && (!!subscription || PRO_USERS.includes(user.id)),
    isExhausted: () => false,
  },
  {
    id: FeatureName.WIDGETS_AMOUNT,
    isEnabled: () => true,
    isExhausted: ({ user, subscription, widgets }) => {
      if (!widgets) {
        return false
      }

      if (user && PRO_USERS.includes(user.id)) {
        return false
      }

      if (subscription) {
        return widgets?.length >= WIDGETS_PRO_MAX_AMOUNT
      } else {
        return widgets?.length >= WIDGETS_FREE_MAX_AMOUNT
      }
    },
  },
]
