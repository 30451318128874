import { Widget } from "domainModels/widget"
import { ReactNode } from "react"
import { ResponsiveProps } from "react-grid-layout"

export enum Breakpoints {
  XS = "xs",
  MD = "md",
  LG = "lg",
  XL = "xl",
  XXL = "xxl",
}

export interface WidgetConfig {
  slug: string
  icon?: ReactNode
  renderModal: (props?: WidgetModalProps) => ReactNode
  renderPreview?: (widget: Widget) => ReactNode
}

export interface WidgetModalProps<T = any> {
  input?: T
  widget?: Widget
}

export const GRID_DRAGGABLE_HANDLE: NonNullable<ResponsiveProps["draggableHandle"]> = ".gridItemDraggable"
export const GRID_ROW_HEIGHT: NonNullable<ResponsiveProps["rowHeight"]> = 12
export const DROPPING_ITEM = {
  i: "__droppingItem__",
  w: 2,
  h: 5,
  minW: 2,
  minH: 5,
}
export const GRID_BREAKPOINTS: NonNullable<ResponsiveProps["breakpoints"]> = {
  [Breakpoints.XS]: 0,
  [Breakpoints.MD]: 768,
  [Breakpoints.LG]: 1024,
  [Breakpoints.XL]: 1280,
  [Breakpoints.XXL]: 1536,
}
export const GRID_COLS: NonNullable<ResponsiveProps["cols"]> = {
  [Breakpoints.XS]: 2,
  [Breakpoints.MD]: 4,
  [Breakpoints.LG]: 6,
  [Breakpoints.XL]: 6,
  [Breakpoints.XXL]: 8,
}
export const GRID_MARGIN: NonNullable<ResponsiveProps["margin"]> = {
  [Breakpoints.XS]: [0, 20],
  [Breakpoints.MD]: [20, 20],
  [Breakpoints.LG]: [20, 20],
  [Breakpoints.XL]: [20, 20],
  [Breakpoints.XXL]: [20, 20],
}

export const DEFAULT_CUSTON_WIDGET_BREAKPOINT = {
  w: 2,
  h: 5,
  x: 0,
  y: -1, // -1 to make it always appear at the very top when you add a new widget
  minW: 1,
  minH: 5,
}

export const DEFAULT_CUSTON_WIDGET_LAYOUT = {
  xs: DEFAULT_CUSTON_WIDGET_BREAKPOINT,
  md: DEFAULT_CUSTON_WIDGET_BREAKPOINT,
  lg: DEFAULT_CUSTON_WIDGET_BREAKPOINT,
  xl: DEFAULT_CUSTON_WIDGET_BREAKPOINT,
  xxl: DEFAULT_CUSTON_WIDGET_BREAKPOINT,
}
