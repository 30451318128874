import toast from "react-hot-toast"
import { useMutation } from "react-query"

export enum FeedbackType {
  ISSUE = "issue",
  IDEA = "idea",
  OTHER = "other",
}

export enum FeedbackTopic {
  GENERAL = "general",
  WIDGET = "widget",
}

export const useAddFeedback = () =>
  useMutation<void, Error, { type: FeedbackType; value: string; topic: FeedbackTopic; email: string; key: string }>(
    async (feedback) => {
      const res = await fetch("/api/feedback", {
        method: "POST",
        body: JSON.stringify(feedback),
      })

      if (!res.ok) {
        throw Error(res.statusText)
      }
    },
    {
      onError: () => {
        toast.error(`Unfortunately something went wrong. Please try again later.`)
      },
      onSuccess: () => {
        toast.success(`Your feedback was sent. Thanks a lot!`)
      },
    }
  )
