import { FileUpload } from "components/core/fileUpload"
import React, { ChangeEvent, FC } from "react"
import toast from "react-hot-toast"

interface Props {
  id: string
  onChange: (svg: string) => void
}

export const SvgFileUpload: FC<Props> = ({ id, onChange }) => {
  const onUpload = async (ev: ChangeEvent<HTMLInputElement>) => {
    const file = ev.target.files?.[0]

    if (file) {
      if (file.type !== "image/svg+xml") {
        toast.error("You can only upload a SVG here.", {
          duration: 7000,
        })
        return
      }

      const reader = new FileReader()
      reader.readAsText(file, "UTF-8")
      reader.onload = async (evt) => {
        const svgString = evt.target?.result

        if (typeof svgString === "string") {
          onChange(svgString)
        }
      }
    }
  }

  return (
    <form encType="multipart/form-data" onSubmit={(ev) => ev.preventDefault()} className="h-full flex">
      <FileUpload id={id} accept=".svg" onUpload={onUpload} text="or paste SVG code" />
    </form>
  )
}
