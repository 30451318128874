import "lib/css/global.css"
import React, { FC } from "react"
import { Providers } from "components/providers/providers"
import Router from "next/router"
import { trackPageview } from "lib/gtag"
import { Layout } from "components/layout/layout"
import { AppProps } from "next/dist/shared/lib/router/router"
import { Clipboard } from "components/core/clipboard"
import { CookieBanner } from "components/layout/cookieBanner"
import { NextPageContext } from "next"

Router.events.on("routeChangeComplete", () => {
  trackPageview()
})

const App: FC<AppProps & { err: NextPageContext["err"] }> = ({ Component, pageProps, err }) => (
  <Providers pageProps={pageProps}>
    <Clipboard />
    <CookieBanner />
    <Layout>
      <Component {...pageProps} err={err} />
    </Layout>
  </Providers>
)

export default App
