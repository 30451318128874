import { Group } from "domainModels/group"
import { supabase } from "lib/supabase/client"
import { insertLayout } from "repository/layout"
import { Layout } from "domainModels/layout"
import { SupabaseClient } from "@supabase/supabase-js"

export const selectGroups = async (dashboardId: number, supabaseClient?: SupabaseClient) => {
  const client = supabaseClient ?? supabase
  const { data } = await client
    .from("widget")
    .select(
      `
                dashboard_id,
                group_id,
                group: widget_group_id_fkey (
                    id,
                    user_id,
                    is_private,
                    layout: group_layout_id_fkey (
                        id,
                        user_id,
                        is_private,
                        xs,
                        md,
                        lg,
                        xl,
                        xxl            
                    ),
                    name
                )
            `
    )
    .eq("dashboard_id", dashboardId)
    .not("group_id", "is", null)
    .throwOnError()

  if (!data) {
    return null
  }
  // Distinct queries are not supported by the supabase client.
  const distinctGroupMap = new Map<number, any>()
  data.forEach((record) => {
    // @ts-ignore
    distinctGroupMap.set(record.group_id, record.group)
  })
  const distinctGroupData = Array.from(distinctGroupMap.values())
  return distinctGroupData.map(
    (record) =>
      new Group({
        id: record.id,
        name: record.name,
        user_id: record.user_id,
        is_private: record.is_private,
        layout: new Layout({
          ...record.layout,
          user_id: record.layout.user_id,
        }),
      })
  )
}

export const updateGroup = async (group: Group) => {
  if (group.id === null) {
    throw new Error("Group instance has no id. Cannot perform update.")
  }
  if (typeof group.id === "string" || typeof group.layout.id === "string") {
    throw new Error("Insertion with uuid not allowed")
  }
  const { count } = await supabase
    .from("group")
    .update(
      {
        layout_id: group.layout.id,
        name: group.name,
      },
      { count: "exact" }
    )
    .eq("id", group.id)
    .throwOnError()
  if (count === 0) {
    throw new Error("No group rows were updated.")
  }
}

export const insertGroup = async (group: Group) => {
  if (group.id !== null) {
    throw new Error("No manual PRIMARY KEY insertion for type SERIAL.")
  }

  if (typeof group.layout.id === "string") {
    throw new Error("Insertion with uuid not allowed")
  }

  if (group.layout.id) {
    const { data, error, status } = await supabase
      .from("group")
      .insert({
        layout_id: group.layout.id,
        name: group.name,
      })
      .select()
      .single()
    if (error && status !== 406) {
      throw new Error(error.message)
    }
    if (!data) {
      throw new Error("No record returned for group insertion.")
    }
    return new Group({
      // @ts-ignore
      ...data,
      layout: group.layout,
    })
  } else {
    const layout = await insertLayout(group.layout)

    if (typeof layout.id === "string") {
      throw new Error("Insertion with uuid not allowed")
    }

    const { data, error, status } = await supabase
      .from("group")
      .insert({
        layout_id: layout.id,
        name: group.name,
      })
      .select()
      .single()
    if (error && status !== 406) {
      throw new Error(error.message)
    }
    if (!data) {
      throw new Error("No record returned for group insertion.")
    }
    return new Group({
      // @ts-ignore
      id: data.id,
      // @ts-ignore
      name: data.name,
      // @ts-ignore
      user_id: data.user_id,
      // @ts-ignore
      is_private: data.is_private,
      layout: new Layout({
        // @ts-ignore
        id: data.layout_id,
        xs: group.layout.xs,
        md: group.layout.md,
        lg: group.layout.lg,
        xl: group.layout.xl,
        xxl: group.layout.xxl,
        user_id: group.layout.user_id,
        // @ts-ignore
        is_private: data.is_private,
      }),
    })
  }
}

export const deleteGroup = async (group: Group) => {
  if (group.id === null) {
    throw new Error("Group instance has no id. Cannot perform delete.")
  }
  if (group.layout.id === null) {
    throw new Error("Layout instance has no id. Cannot perform delete.")
  }
  if (typeof group.id === "string" || typeof group.layout.id === "string") {
    throw new Error("Insertion with uuid not allowed")
  }

  await Promise.all([deleteGroupLayout(group.layout.id), deleteGroupRow(group.id as number)])
}

const deleteGroupRow = async (groupId: number) => {
  const { count } = await supabase
    .from("group")
    .delete({
      count: "exact",
    })
    .eq("id", groupId)
    .throwOnError()
  if (count === 0) {
    throw new Error("No group rows were deleted.")
  }
}

const deleteGroupLayout = async (groupLayoutId: number) => {
  const { count } = await supabase.from("layout").delete({ count: "exact" }).eq("id", groupLayoutId).throwOnError()
  if (count === 0) {
    throw new Error("No layout rows were deleted.")
  }
}
