import { Layout } from "domainModels/layout"

export interface GroupConstructor {
  id?: number | string | null
  layout: Layout
  user_id?: string | null
  is_private: boolean
  name: string
}

export class Group {
  id: Readonly<number | string | null>
  layout: Layout
  user_id: Readonly<string | null>
  is_private: boolean
  name: string

  constructor({ id, layout, name, user_id, is_private }: GroupConstructor) {
    Group.validateUserIdAndIsPrivate(user_id, is_private)

    this.id = id ?? null
    this.layout = layout
    this.user_id = user_id ?? null
    this.is_private = is_private
    this.name = name
  }

  private static validateUserIdAndIsPrivate = (
    userId: GroupConstructor["user_id"],
    isPrivate: GroupConstructor["is_private"]
  ) => {
    if (!userId && isPrivate === true) {
      throw new Error("Group without a user_id cannot be private.")
    }
  }
}
