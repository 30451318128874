interface DashboardConstructor {
  id?: number | null
  user_id: string
  name: string
  is_primary: boolean
  is_private: boolean
  is_forked: boolean
  is_password_protected: boolean
  owner_name: string
  created_at?: string | null
  owner_avatar?: string | null
  updated_at?: string | null
}

export class Dashboard {
  id: Readonly<number | null>
  user_id: Readonly<string>
  name: string
  is_primary: boolean
  is_private: boolean
  is_forked: boolean
  is_password_protected: boolean
  owner_name: string
  owner_avatar: string | null
  created_at: string | null
  updated_at: string | null

  constructor({
    id,
    user_id,
    name,
    is_primary,
    is_private,
    is_password_protected,
    is_forked,
    owner_name,
    created_at,
    owner_avatar,
    updated_at,
  }: DashboardConstructor) {
    this.id = id ?? null
    this.user_id = user_id
    this.is_primary = is_primary
    this.is_private = is_private
    this.is_forked = is_forked
    this.is_password_protected = is_password_protected
    this.owner_name = owner_name
    this.created_at = created_at ?? null
    this.owner_avatar = owner_avatar ?? null
    this.updated_at = updated_at ?? null
    this.name = name
  }
}

export interface PublicDashboardUser {
  dashboardId: number | null
  name: string
  avatar: string | null
  isPro: boolean
  createdAt: string
}
