import classNames from "classnames"
import { ButtonHTMLAttributes, FC, ReactNode, useState } from "react"

interface Props extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, "onChange"> {
  className?: string
  showIcons?: boolean
  initiallyEnabled?: boolean
  label?: ReactNode
  onChange?: (enabled: boolean) => void
}

export const Toggle: FC<Props> = ({
  onChange,
  className,
  label,
  showIcons = true,
  initiallyEnabled = false,
  disabled,
  ...buttonProps
}) => {
  const [enabled, setEnabled] = useState(initiallyEnabled)

  const onClick = (enabled: boolean) => {
    setEnabled(enabled)
    onChange?.(enabled)
  }

  return (
    <div className="flex items-center">
      <button
        {...buttonProps}
        type="button"
        disabled={disabled}
        className={classNames(
          "relative inline-flex mr-2.5 flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full transition-colors ease-in-out duration-200 focus-visible:ring-1 focus-visible:ring-indigo-500 focus-visible:border-indigo-500",
          {
            "bg-indigo-600 dark:bg-indigo-600/70": enabled,
            "bg-gray-200 dark:bg-gray-700": !enabled,
            "cursor-pointer": !disabled,
            "opacity-50 cursor-not-allowed": disabled,
          },
          className
        )}
        onClick={() => onClick(!enabled)}
        aria-pressed={enabled}
      >
        <span
          className={classNames(
            "pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200",
            {
              "translate-x-5": enabled,
              "translate-x-0": !enabled,
            }
          )}
        >
          {showIcons && (
            <span
              className={classNames(
                "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity",
                {
                  "opacity-0 ease-out duration-100": enabled,
                  "opacity-100 ease-in duration-200": !enabled,
                }
              )}
              aria-hidden="true"
            >
              <svg className="bg-white h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                <path
                  d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          )}
          {showIcons && (
            <span
              className={classNames(
                "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity",
                {
                  "opacity-100 ease-in duration-200": enabled,
                  "opacity-0 ease-out duration-100": !enabled,
                }
              )}
              aria-hidden="true"
            >
              <svg className="bg-white h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
              </svg>
            </span>
          )}
        </span>
      </button>

      {label && (
        <button
          type="button"
          disabled={disabled}
          className={classNames("block text-sm font-medium text-gray-700 dark:text-gray-300", {
            "cursor-pointer": !disabled,
            "cursor-not-allowed": disabled,
          })}
          onClick={() => onClick(!enabled)}
          aria-pressed={enabled}
        >
          {label}
        </button>
      )}
    </div>
  )
}
