import { LockClosedIcon, TrashIcon } from "@heroicons/react/24/outline"
import classNames from "classnames"
import { Button } from "components/core/button"
import { InputLabel } from "components/core/inputLabel"
import { Link } from "components/core/link"
import { Modal } from "components/core/modal/modal"
import { Title } from "components/core/modal/title"
import { ShareContent } from "components/core/share"
import { Toggle } from "components/core/toggle"
import { Tooltip } from "components/core/tooltip"
import { TOAST_DASHBOARD_REMOVE, TOAST_DASHBOARD_UPDATE } from "components/dashboard/toastConfig"
import {
  useDeleteDashboard,
  useSelectDashboards,
  useUpdateDashboard,
  useUpdateDashboardIsPrivate,
} from "reactQuery/dashboard"
import { Dashboard } from "domainModels/dashboard"
import { Formik } from "formik"
import { FeatureName } from "lib/features/features"
import { useFeature } from "lib/features/useFeature"
import { useUser } from "lib/supabase/auth"
import { BASE_URL, getDashboardSlug } from "lib/utils"
import { useRouter } from "next/router"
import React, { FC, useState } from "react"
import toast from "react-hot-toast"
import { useSelectPublicWidgets, useSelectWidgets, useUpdateWidget } from "reactQuery/widget"
import { useDeleteDashboardPassword, useUpsertDashboardPassword } from "reactQuery/usePasswordProtected"
import { Divider } from "components/core/divider"

const DUMMY_PW = "FETOOLKIT_PASSWORD"

interface Props {
  dashboardId: number
  onClose: () => void
}

export const EditDashboardModal: FC<Props> = ({ onClose, dashboardId }) => {
  const { user } = useUser()
  const { replace } = useRouter()
  const [showRemoveModal, setShowRemoveModal] = useState(false)
  const { data: dashboards } = useSelectDashboards()
  const { data: widgets } = useSelectWidgets(dashboardId)
  const { data: publicWidgets } = useSelectPublicWidgets()
  const { mutateAsync: updateDashboard } = useUpdateDashboard()
  const { mutateAsync: deleteDashboard, isLoading: isDeletingDashboard } = useDeleteDashboard()
  const { mutateAsync: updateWidget } = useUpdateWidget()
  const { mutateAsync: updateDashboardIsPrivate } = useUpdateDashboardIsPrivate()
  const { mutateAsync: updateDashboardPassword } = useUpsertDashboardPassword()
  const { mutateAsync: deleteDashboardPassword } = useDeleteDashboardPassword()
  const { enabled: dashboardPrivateEnabled } = useFeature(FeatureName.DASHBOARD_PRIVATE)
  const dashboard = dashboards?.find((d) => d.id === dashboardId)
  const primaryDashboard = dashboards?.find((d) => d.is_primary)
  const isAlreadyPrimary = dashboard?.is_primary

  const updateIsPrivate = async (dashboardId: number, isPrivate: boolean) => {
    const builtInInformationIds = publicWidgets?.map((w) => w.information.id)
    const widgetsThatNeedFork = widgets?.filter((w) => builtInInformationIds?.includes(w.information.id))

    if (widgetsThatNeedFork) {
      await Promise.all(
        widgetsThatNeedFork.map((w) =>
          updateWidget({
            dashboardId,
            widget: {
              ...w,
              information: {
                ...w.information,
                id: null,
              },
            },
          })
        )
      )
    }

    await updateDashboardIsPrivate({ dashboardId, isPrivate })
  }

  const updateAllDashboardsAndChangePrimary = async (newPrimaryDashboard: Dashboard, dashboards: Dashboard[]) => {
    Promise.all(
      dashboards.map((d) =>
        updateDashboard(
          d.id === newPrimaryDashboard.id
            ? { ...d, name: newPrimaryDashboard.name, is_primary: true }
            : { ...d, is_primary: false }
        )
      )
    )
  }

  if (!dashboard) {
    return null
  }

  return (
    <>
      <Title title="Edit dashboard" />

      <Formik
        initialValues={{
          name: dashboard.name,
          is_primary: dashboard.is_primary,
          is_private: dashboard.is_private,
          password: dashboard.is_password_protected ? DUMMY_PW : "",
        }}
        validate={async (values) => {
          const errors: {
            [key: string]: string
          } = {}

          if (!values.name || (values.name && values.name.length < 3)) {
            errors.name = "Your dashboard name should contain at least 3 characters"
          }

          return errors
        }}
        onSubmit={async (values) => {
          if (dashboardId) {
            await toast.promise(
              new Promise<void>(async (resolve) => {
                const primaryDashboardChanged = !isAlreadyPrimary && dashboards && values.is_primary
                const newDashboard: Dashboard = {
                  ...dashboard,
                  name: values.name,
                  is_private: values.is_private,
                  is_primary: values.is_primary,
                }

                if (primaryDashboardChanged) {
                  await updateAllDashboardsAndChangePrimary(newDashboard, dashboards)
                } else {
                  await updateDashboard(newDashboard)
                }

                if (values.is_private !== dashboard.is_private) {
                  await updateIsPrivate(dashboardId, newDashboard.is_private)
                }

                if (values.password && values.password !== DUMMY_PW) {
                  await updateDashboardPassword({
                    dashboardId,
                    password: values.password,
                  })
                }

                resolve()
              }),
              TOAST_DASHBOARD_UPDATE
            )
          }

          onClose()
        }}
      >
        {({ values, errors, touched, setFieldValue, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
          const showPrivateMessage = values.is_private && !values.password

          return (
            <form onSubmit={handleSubmit} autoComplete="off">
              <div className="space-y-6 my-8 max-w-md">
                <InputLabel
                  id="name"
                  type="text"
                  label="Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  errors={errors.name && touched.name ? errors.name : null}
                />
                <div>
                  <Toggle
                    id="is_primary"
                    label={
                      <>
                        <span className="block text-left">Primary dashboard</span>
                        {isAlreadyPrimary && (
                          <small className="font-normal text-xs text-gray-500 flex items-center">
                            You must have one primary dashboard.
                          </small>
                        )}
                      </>
                    }
                    initiallyEnabled={values.is_primary}
                    disabled={isAlreadyPrimary}
                    onChange={(enabled) => setFieldValue("is_primary", enabled)}
                  />
                </div>
                <div>
                  <Toggle
                    id="is_private"
                    label={
                      <span className="flex items-center">
                        <span>
                          <span className="block text-left">Private dashboard</span>
                          <small className="font-normal text-xs text-gray-500 flex items-center">
                            Your private dashboard is only visible to you.
                          </small>
                        </span>
                        {!dashboardPrivateEnabled && (
                          <Tooltip
                            interactive
                            content={
                              <div className="text-left font-normal">
                                Your current plan does not include private dashboards.{" "}
                                <Link to="/upgrade">Upgrade now</Link>
                              </div>
                            }
                          >
                            <span className="relative top-1 block px-4 py-2 text-xs text-gray-500">
                              <LockClosedIcon className="w-4 h-4" />
                            </span>
                          </Tooltip>
                        )}
                      </span>
                    }
                    initiallyEnabled={values.is_private}
                    disabled={!dashboardPrivateEnabled}
                    onChange={(enabled) => setFieldValue("is_private", enabled)}
                  />
                  {values.is_private && (
                    <div className="ml-14 mt-3">
                      <InputLabel
                        id="password"
                        type="password"
                        label="Password"
                        placeholder="Password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password ?? undefined}
                        errors={errors.password && touched.password ? errors.password : null}
                        minLength={5}
                        maxLength={30}
                        inputAfter={
                          dashboard.is_password_protected && (
                            <Button
                              onClick={async () => {
                                toast.promise(deleteDashboardPassword({ dashboardId }), TOAST_DASHBOARD_UPDATE)
                                setFieldValue("password", "")
                              }}
                              variant="danger"
                              size="small"
                              className="rounded-l-none"
                            >
                              <TrashIcon className="w-4 h-4" />
                            </Button>
                          )
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
              <Divider />
              <div>
                {user ? (
                  <div className="relative py-6">
                    <div
                      className={classNames("absolute inset-y-0 -inset-x-6 z-10", {
                        "bg-white dark:bg-gray-900/80 backdrop-blur block": showPrivateMessage,
                        hidden: !showPrivateMessage,
                      })}
                    >
                      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 whitespace-nowrap text-center">
                        Your private dashboard is only visible to <br /> you unless you provide a password protection
                      </div>
                    </div>
                    {dashboard?.id && (
                      <ShareContent
                        title="Share Dashboard"
                        shareInfo={{
                          title: "Dashboard",
                          description: "Checkout my custom Frontend Toolkit dashboard!",
                          url: `${BASE_URL}/dashboard/${getDashboardSlug(dashboard.id, user.id)}`,
                        }}
                      />
                    )}
                  </div>
                ) : (
                  <p className="text-sm py-6">
                    <Link to="/auth/login">Sign in</Link> to share your dashboard with other people!
                  </p>
                )}
              </div>
              <Divider />
              <div className=" mt-12 flex flex-col-reverse md:flex-row justify-between">
                <Button
                  title={isAlreadyPrimary ? "You can't delete your primary dashboard" : undefined}
                  disabled={isAlreadyPrimary}
                  onClick={() => setShowRemoveModal(true)}
                  variant="danger"
                  className="block w-full justify-center md:w-auto mt-4 md:mt-0"
                >
                  Remove dashboard
                </Button>
                <div className="md:flex md:flex-row-reverse">
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    variant="primary"
                    className="block w-full justify-center md:w-auto md:ml-3"
                  >
                    Save dashboard
                  </Button>
                  <Button onClick={onClose} className="block w-full justify-center md:w-auto mt-4 md:mt-0 md:ml-3">
                    Cancel
                  </Button>
                </div>
              </div>
            </form>
          )
        }}
      </Formik>

      <Modal show={showRemoveModal} className="max-w-lg" onClose={() => setShowRemoveModal(false)}>
        <Title title="Remove dashboard" />

        <p className="text-sm">
          You are about to delete your dashboard with the name <strong>{dashboard.name}</strong>. This can not be
          undone. Are you sure?
        </p>

        <div className="mt-5 md:flex md:flex-row-reverse">
          <Button
            type="submit"
            loading={isDeletingDashboard}
            disabled={isDeletingDashboard}
            onClick={async () => {
              if (primaryDashboard?.id && user) {
                replace("/dashboard/[slug]", `/dashboard/${getDashboardSlug(primaryDashboard.id, user.id)}`)
              }

              await toast.promise(deleteDashboard(dashboard), TOAST_DASHBOARD_REMOVE)

              onClose()
            }}
            variant="danger"
            className="w-full md:w-auto md:ml-3"
          >
            Remove
          </Button>
          <Button onClick={() => setShowRemoveModal(false)} className="w-full md:w-auto mt-4 md:mt-0">
            Cancel
          </Button>
        </div>
      </Modal>
    </>
  )
}
