import React, { FC, Fragment, ReactNode, useEffect, useRef } from "react"
import { Menu, Transition } from "@headlessui/react"
import classNames from "classnames"

interface Props {
  open: boolean
  trigger: ReactNode
  position?: "right"
  menuItems: ReactNode[]
}

export const WidgetMenuContent: FC<Props> = ({ open, trigger, position = "right", menuItems }) => {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const gridItem = containerRef.current?.closest<HTMLDivElement>(".react-grid-item")

  useEffect(() => {
    if (open && gridItem) {
      gridItem.style.zIndex = "10"
    }

    return () => {
      if (gridItem) {
        gridItem.style.zIndex = ""
      }
    }
  }, [gridItem, open])

  return (
    <div ref={containerRef} className="relative">
      {trigger}

      <Transition
        show={open}
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={classNames(
            "absolute z-20 mt-2 py-1 w-48 rounded-md shadow-lg bg-white text-sm text-gray-700 ring-1 ring-black ring-opacity-5 dark:bg-gray-900 divide-y divide-gray-200 dark:divide-gray-800/40",
            {
              "right-0": position === "right",
            }
          )}
        >
          {menuItems.map((item, i) => (
            <Fragment key={i}>{item}</Fragment>
          ))}
        </Menu.Items>
      </Transition>
    </div>
  )
}

export const WidgetMenuContentItem: FC<{ onClick?: () => void }> = ({ onClick, children }) => (
  <Menu.Item onClick={onClick} as="button" className="w-full">
    {({ active }) => (
      <span
        className={classNames("inline-flex items-center px-4 py-3 w-full text-gray-700 dark:text-gray-300", {
          "bg-gray-100 dark:bg-gray-800/40": active,
        })}
      >
        {children}
      </span>
    )}
  </Menu.Item>
)
