import classNames from "classnames"
import { FC, InputHTMLAttributes, ReactNode } from "react"

export const FORM_BASE_CLASS_NAMES =
  "rounded-md block w-full text-sm border-gray-300 dark:bg-gray-700/10 dark:border-gray-700 dark:text-gray-200"

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  id: string
  label?: ReactNode
  inputClassName?: string
  inputBefore?: ReactNode
  inputIconBefore?: ReactNode
  inputIconAfter?: ReactNode
  inputAfter?: ReactNode
  errors?: ReactNode
}

export const InputLabel: FC<Props> = ({
  className,
  id,
  label,
  inputClassName,
  inputBefore,
  inputIconBefore,
  inputAfter,
  inputIconAfter,
  errors,
  disabled,
  ...rest
}) => (
  <div>
    {label && (
      <label htmlFor={id} className="block mb-1 text-sm font-medium text-gray-700 dark:text-gray-300">
        {label}
      </label>
    )}
    <div className={classNames("flex rounded-md shadow-sm", className)}>
      {inputBefore}
      <div className="relative flex items-stretch flex-grow">
        {inputIconBefore && (
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">{inputIconBefore}</div>
        )}

        <input
          id={id}
          name={id}
          disabled={disabled}
          className={classNames(
            FORM_BASE_CLASS_NAMES,
            {
              "pl-10": inputIconBefore,
              "rounded-r-none": inputAfter,
              "bg-gray-100 dark:bg-gray-900 text-gray-600 dark:text-gray-400 cursor-not-allowed": disabled,
            },
            inputClassName
          )}
          {...rest}
        />

        {inputIconAfter && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">{inputIconAfter}</div>
        )}
      </div>
      {inputAfter}
    </div>
    {errors && <div className="mt-2 text-sm text-red-600 dark:text-[#e5484d]">{errors}</div>}
  </div>
)
