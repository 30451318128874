import classNames from "classnames"
import { FC } from "react"

export const RoundedDivider: FC<{ className: string }> = ({ className }) => (
  <div className="absolute z-10 top-0 inset-x-0">
    <svg
      className={classNames("w-full -mt-px", className)}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500 4"
    >
      <path d="M249 4C0 4 0 0 0 0h500s-2 4-251 4z" fill="currentColor" />
    </svg>
  </div>
)

export const Divider: FC<{ className?: string }> = ({ className }) => (
  <div
    className={classNames(
      "h-px bg-gradient-to-r from-indigo-500 via-indigo-900 dark:via-indigo-100 to-indigo-500 opacity-10 dark:opacity-5",
      className
    )}
  />
)
