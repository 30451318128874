import classNames from "classnames"
import { Spinner } from "components/core/spinner"
import React, { ButtonHTMLAttributes, FC, MutableRefObject } from "react"

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string
  loading?: boolean
  variant?: "primary" | "secondary" | "naked" | "link" | "danger"
  size?: "small" | "regular" | "big"
  to?: string
  innerRef?: MutableRefObject<HTMLButtonElement | null>
}

export const Button: FC<ButtonProps> = ({
  children,
  className,
  loading,
  disabled,
  variant = "secondary",
  size = "regular",
  to,
  innerRef,
  ...props
}) => (
  <button
    disabled={disabled}
    type={props.type ?? "button"}
    className={classNames(getButtonClassNames(variant, size, disabled), className)}
    ref={innerRef}
    {...props}
  >
    {loading && <Spinner className="w-4 h-4 mr-3" />}
    {children}
  </button>
)

export const getButtonClassNames = (variant: ButtonProps["variant"], size: ButtonProps["size"], disabled?: boolean) =>
  classNames(
    "inline-flex text-sm items-center border font-medium rounded-md focus-visible:ring-indigo-500 transition-all",
    {
      "border-gray-300 text-gray-600 hover:bg-gray-100 bg-white dark:text-gray-300 dark:hover:text-gray-100 dark:bg-[#2b3046] dark:border-gray-700 dark:hover:bg-gray-600/60 dark:hover:border-gray-600 focus-visible:ring-1 focus-visible:!border-indigo-500":
        variant === "secondary",
      "bg-indigo-600 border-indigo-600 text-white hover:bg-indigo-700 hover:border-indigo-700 dark:border-indigo-600/70 dark:bg-indigo-600/70 hover:dark:bg-indigo-600 focus-visible:ring-1 focus-visible:!border-indigo-500":
        variant === "primary",
      "border-red-600 bg-red-600 text-white hover:bg-red-700 dark:border-red-600/70 dark:bg-red-600/70 hover:dark:bg-red-600 focus-visible:ring-1 focus-visible:!border-indigo-500":
        variant === "danger",
      "border-transparent text-gray-500 hover:text-gray-600 dark:text-gray-400 dark:hover:text-gray-100 focus-visible:ring-2 focus-visible:text-gray-700 focus-visible:ring-0 dark:focus-visible:text-white":
        variant === "naked",
      "border-0 rounded-md inline-flex items-center font-medium text-indigo-600 hover:text-indigo-900 dark:text-indigo-400 dark:hover:text-indigo-500 focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:border-indigo-500":
        variant === "link",
      "px-6 py-3": size === "big" && variant !== "link",
      "px-4 py-2": size === "regular" && variant !== "link",
      "px-2.5 py-1.5": size === "small" && variant !== "link",
      "opacity-50 cursor-not-allowed": !!disabled,
    }
  )
